<script lang="ts" setup>
import { usePage, WhenVisible } from '@inertiajs/vue3'
import { GuestResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'

const page = usePage()

const guests = computed<GuestResource[]>(() => page.props.guests)
</script>

<template>
  <div
    id="guests"
    class="w-full scroll-mt-20"
  >
    <div>
      <div class="flex items-center justify-center flex-col text-center">
        <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
          Key Guests
        </h2>
        <div class="flex items-center flex-row justify-center">
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
          <div class="flex items-center">
            <LavenderIcon />
          </div>
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 divide-y sm:divide-y-0">
      <WhenVisible
        data="guests"
        :buffer="500"
      >
        <div
          v-for="(guest, index) in guests"
          :key="index"
          class="flex items-center p-5 sm:p-10"
          :class="{'sm:border-r sm:border-purple-300': index % 2 === 0}"
        >
          <div class="flex-shrink-0">
            <img
              class="h-36 w-36 rounded-full"
              :src="guest.avatar"
              alt=""
            >
          </div>
          <div class="flex-1 min-w-0 justify-start px-4 space-y-2">
            <p class="text-xl font-medium text-gray-900 font-serif">
              {{ guest.name }}
            </p>
            <p
              class="text-sm text-gray-500 leading-5"
              v-html="guest.bio"
            />
          </div>
        </div>
      </WhenVisible>
    </div>
  </div>
</template>
