<script lang="ts" setup>
import { computed, watch, ref, onMounted, onUnmounted, inject } from 'vue'
import { Commentable, CommentResource, UserResource } from '@/Types/generated'
import { CommentResponse } from '@/Types/comments'
import { usePage, Link } from '@inertiajs/vue3'
import CommentPage from '@/Components/Comments/CommentPage.vue'
import Comment from '@/Components/Comments/Comment.vue'
import axios from 'axios'
import TextAreaWithMentions from '@/Components/UI/Inputs/TextAreaWithMentions.vue'
import { route as routeFn } from 'ziggy-js'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'

const route = inject<typeof routeFn>('route')

interface Page {
  page: number;
  count: number;
}

const props = defineProps<{
  commentableType: Commentable;
  commentableId: string;
  commentCount: number;
  isNested?: boolean;
  readonly?: boolean;
  noSockets?: boolean;
}>()

const page = usePage()

const newComments = ref<CommentResource[]>([])

const user = computed<UserResource | undefined>(() => page.props.user ?? undefined)

const isNested = computed(() => props.isNested || false)

const perPage = 10

const form = ref<{
  comment: string;
  commentable_type: Commentable;
  commentable_id: string;
}>({
  comment: '',
  commentable_type: props.commentableType,
  commentable_id: props.commentableId
})

const commentPages = computed<Page[]>(() => {
  const pages: Page[] = []

  const totalPages = Math.ceil(props.commentCount / perPage)
  const remainder = props.commentCount % perPage ? props.commentCount % perPage : perPage

  for (let i = 1; i <= totalPages; i++) {
    pages.push({
      page: i,
      count: i === totalPages ? remainder : perPage
    } as Page)
  }

  return pages
})

function onSubmit () {
  if (props.readonly) {
    return
  }

  axios.post(route('api.comments.store'), form.value)
    .then((response: {data: CommentResponse}) => {
      form.value.comment = ''
      newComments.value.unshift(response.data.comment)
    })
    .catch(() => {
      // Do nothing
    })
}

watch(
  () => props.commentableId,
  () => {
    form.value.commentable_id = props.commentableId
  }
)

watch(
  () => props.commentableType,
  () => {
    form.value.commentable_type = props.commentableType
  }
)

const submitButtonClasses = computed(() => [
  'mt-2 px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500',
  'transition-colors duration-200',
  isNested.value ? 'text-sm' : ''
])

const fetchComment = (commentId: string) => {
  axios.get<CommentResource>(route('api.comments.show', { comment: commentId }))
    .then((response) => {
      newComments.value.unshift(response.data)
    })
    .catch(() => {
      // Do nothing
    })
}

onMounted(() => {
  if (props.noSockets) {
    return
  }

  /* eslint-disable */
  window.Echo.private(`Commentable.${props.commentableType}.${props.commentableId}`)
    .listen('.CommentCreated', (e: { commentId: string }) => {
      if (newComments.value.find((comment) => comment.id == e.commentId)) {
        return
      }

      fetchComment(e.commentId)
    })

  onUnmounted(() => {
    window.Echo.leave(`Commentable.${props.commentableType}.${props.commentableId}`)
  })
  /* eslint-enable */
})
</script>

<template>
  <div :class="{ 'ml-8': isNested }">
    <div
      v-if="user && !readonly"
      class="comment-form mb-6"
    >
      <div class="flex items-center mb-2">
        <img
          :src="user.guest?.avatar"
          :alt="user.name"
          class="w-8 h-8 rounded-full mr-2"
          loading="lazy"
        >
        <span class="font-semibold text-gray-800">{{ user.name }}</span>
      </div>
      <form
        @submit.prevent="onSubmit"
      >
        <TextAreaWithMentions
          v-model="form.comment"
          placeholder="Add your comment..."
          :rows="isNested ? 2 : 4"
          required
          aria-label="Add a comment"
        />
        <PrimaryButton
          type="submit"
          :class="submitButtonClasses"
          :disabled="form.processing"
        >
          <span v-if="form.processing">Submitting...</span>
          <span v-else>Submit</span>
        </PrimaryButton>
      </form>
    </div>
    <div
      v-else-if="!isNested"
      class="mb-6 text-center"
    >
      <p>
        <Link
          :href="route('login')"
          class="text-purple-600 hover:underline"
        >
          Log in
        </Link>
        to leave a comment.
      </p>
    </div>

    <div>
      <Comment
        v-for="comment in newComments"
        :key="comment.id"
        :comment="comment"
      />

      <CommentPage
        v-for="commentPage in commentPages"
        :key="commentPage.page"
        :page="commentPage"
        :commentable-id="props.commentableId"
        :commentable-type="props.commentableType"
        :is-nested="false"
      />
    </div>
  </div>
</template>

<style scoped>
.ml-8 {
  margin-left: 2rem;
}
</style>
