<script lang="ts" setup>
import { usePage, Link, WhenVisible } from '@inertiajs/vue3'
import { ContentSettings, ActivityDTO, UserResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'
import ActivityCard from '@/Components/Activities/ActivityCard.vue'

const page = usePage()

const activities = computed<ActivityDTO[]|undefined>(() => page.props.activities)
const user = computed<UserResource>(() => page.props.user)
const contentSettings = computed<ContentSettings>(() => page.props.contentSettings)
</script>

<template>
  <div
    id="activities"
    class="w-full mt-16 scroll-mt-20"
  >
    <div class="flex items-center justify-center flex-col text-center">
      <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
        Activities
      </h2>
      <div class="flex items-center flex-row justify-center mb-8">
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
        <div class="flex items-center">
          <LavenderIcon />
        </div>
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
      </div>
    </div>
    <p class="mt-4 text-gray-600 max-w-2xl">
      {{ contentSettings.activities_content }}
    </p>

    <div class="max-w-6xl mx-auto px-4 sm:px-0">
      <div class="flex flex-col gap-4">
        <WhenVisible
          data="activities"
          :buffer="500"
        >
          <ActivityCard
            v-for="activity in activities"
            :key="activity.id"
            :activity="activity"
          />

          <template #fallback>
            <ActivityCard
              v-for="index in 8"
              :key="index"
            />
          </template>
        </WhenVisible>
      </div>
    </div>

    <div class="flex justify-center space-x-4 mt-6">
      <Link
        v-if="user"
        prefetch
        :href="route('activities.create')"
        class="inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
      >
        Add an Activity
      </Link>
      <Link
        prefetch
        :href="route('activities.index')"
        class="inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
      >
        View All Activities
      </Link>
    </div>
  </div>
</template>
