<script lang="ts" setup>
import { defineProps, defineEmits, computed, inject } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { UserResource } from '@/Types/generated'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const props = defineProps<{ show: boolean }>()
const emit = defineEmits(['close'])
const page = usePage()

const user = computed<UserResource|undefined>(() => page.props.user)

const form = useForm<{ email: string }>({
  email: user.value?.email ?? ''
})

const onSubmit = () => {
  form.post(route('media.exports.store'), {
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => { emit('close') }
  })
}

const onClose = () => {
  emit('close')
}
</script>

<template>
  <DialogModal :show="props.show">
    <template #title>
      <h2 class="text-lg font-medium text-gray-800">
        Export Media
      </h2>
    </template>
    <template #content>
      <div class="mt-2">
        <label
          for="email"
          class="block text-sm font-medium text-gray-700 dark:text-gray-400"
        >Email</label>
        <input
          id="email"
          v-model="form.email"
          name="email"
          type="email"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
        >
        <div
          v-if="form.errors.email"
          class="text-red-500 text-sm mt-1"
        >
          {{ form.errors.email }}
        </div>
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
      <button
        type="button"
        class="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onSubmit"
      >
        Submit
      </button>
    </template>
  </DialogModal>
</template>
