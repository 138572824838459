<script lang="ts" setup>
import { ref, onUnmounted } from 'vue'
import { CalendarLinksResource } from '@/Types/generated'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'

const props = defineProps<{
    calendarLinks: CalendarLinksResource
}>()

const isDropdownOpen = ref(false)

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const closeDropdown = () => {
  isDropdownOpen.value = false
}

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (!target.closest('#add-to-calendar-dropdown')) {
    closeDropdown()
  }
}

window.addEventListener('click', handleClickOutside)

onUnmounted(() => {
  window.removeEventListener('click', handleClickOutside)
})

const calendarOptions = [
  { type: 'google', name: 'Google', icon: 'fab fa-google' },
  { type: 'outlook', name: 'Outlook', icon: 'fab fa-microsoft' },
  { type: 'yahoo', name: 'Yahoo', icon: 'fab fa-yahoo' },
  { type: 'web_office', name: 'Web Office', icon: 'fas fa-calendar-alt' },
  { type: 'ics', name: 'ICS', icon: 'fas fa-file-download' }
]

const handleSelect = (link: string) => {
  window.open(link, '_blank')
  closeDropdown()
}
</script>

<template>
  <div
    id="add-to-calendar-dropdown"
    class="relative inline-block"
  >
    <PrimaryButton
      aria-haspopup="true"
      :aria-expanded="isDropdownOpen.toString()"
      aria-label="Add to Calendar"
      @click="toggleDropdown"
    >
      <i class="fas fa-calendar-plus mr-2" />
      Add to Calendar
      <i
        :class="isDropdownOpen ? 'fas fa-chevron-up ml-2' : 'fas fa-chevron-down ml-2'"
        class="ml-2"
      />
    </PrimaryButton>

    <transition name="fade">
      <div
        v-if="isDropdownOpen"
        class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20"
      >
        <ul class="py-1">
          <li
            v-for="option in calendarOptions"
            :key="option.type"
            class="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            @click="handleSelect(props.calendarLinks[option.type as keyof CalendarLinksResource])"
          >
            <i
              :class="option.icon"
              class="mr-3"
            />
            <span>{{ option.name }}</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
