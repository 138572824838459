<script lang="ts" setup>
import NavigationSection from '@/Sections/IndexPage/NavigationSection.vue'

const props = withDefaults(
  defineProps<{
    dark?: boolean,
    withGalleryButtons?: boolean
  }>(),
  {
    dark: false,
    withGalleryButtons: false
  }
)
</script>
<template>
  <NavigationSection
    :dark="props.dark"
    :with-gallery-buttons="props.withGalleryButtons"
  />
  <slot />
</template>
