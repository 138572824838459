<script lang="ts" setup>
import ExcursionCard from '@/Components/Excursions/ExcursionCard.vue'
import { defineEmits, ref, inject } from 'vue'
import axios from 'axios'
import { ExcursionResource } from '@/Types/generated'
import { ExcursionFilters } from '@/Types/excursions'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface AllExcursionsResponse {
  data: ExcursionResource[]
}

const props = defineProps<{ page: {page: number, count: number}, filters: ExcursionFilters, search: string | null }>()

const hasLoaded = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const emit = defineEmits(['open-modal'])

const allExcursions = ref<AllExcursionsResponse>({
  data: []
})

const loadExcursion = async (isVisible: boolean) => {
  if (isLoading.value || !isVisible) {
    return
  }

  isLoading.value = true

  try {
    const response = await axios.get(route('api.excursions.index', {
      page: props.page.page,
      filters: props.filters,
      search: props.search
    }))

    allExcursions.value = response.data as AllExcursionsResponse
    hasLoaded.value = true
  } finally {
    isLoading.value = false
  }
}

const openModal = (excursion: ExcursionResource) => {
  emit('open-modal', excursion)
}
</script>

<template>
  <template v-if="hasLoaded">
    <ExcursionCard
      v-for="(excursion, index) in allExcursions.data"
      :key="index"
      :excursion="excursion"
      @open-modal="openModal"
    />
  </template>
  <template v-else>
    <ExcursionCard
      v-for="excursionCard in props.page.count"
      :key="excursionCard + '-' + props.page.page"
      v-observe-visibility="{
        callback: loadExcursion,
        throttle: 300,
      }"
    />
  </template>
</template>
