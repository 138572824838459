<script lang="ts" setup>
import { ref, computed, inject } from 'vue'
import { usePage, Link, useForm } from '@inertiajs/vue3'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import { ContentSettings, UserResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const page = usePage()

const contentSettings = computed<ContentSettings>(() => page.props.contentSettings)
const user = computed<UserResource|null>(() => page.props.user)

const form = useForm<{ email: string; }>({
  email: user.value?.email ?? ''
})

const hasSubscribedViaForm = ref<boolean>(false)

const isEmailLocked = computed((): boolean => {
  return !!user.value && !user.value.has_newsletter
})

const hasSubscribed = computed((): boolean => {
  return (!!user.value && !!user.value.has_newsletter) || hasSubscribedViaForm.value
})

const onSubmit = (): void => {
  form.post(route('newsletter.subscribe'), {
    only: ['flash', 'errors'],
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => {
      hasSubscribedViaForm.value = true
    },
    onError: (errors) => {
      console.error('Subscription failed:', errors)
    }
  })
}
</script>

<template>
  <div
    id="newsletter"
    class="w-full bg-purple-100 mt-8 mb-8 pt-8 scroll-mt-20"
  >
    <div>
      <div class="flex items-center justify-center flex-col text-center">
        <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
          Newsletter
        </h2>
        <div class="flex items-center flex-row justify-center">
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
          <LavenderIcon />
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
        </div>
      </div>
    </div>

    <div class="text-center space-y-4 max-w-4xl mx-auto px-4 sm:px-0 ">
      <p
        class="leading-1 text-gray-500 font-sans"
        v-html="contentSettings.newsletter_content"
      />
      <div class="max-w-3xl mx-auto">
        <div class="px-4 py-5 ">
          <div v-if="!hasSubscribed">
            <form
              class="flex items-center space-x-2"
              @submit.prevent="onSubmit"
            >
              <div class="w-2/3">
                <TextInput
                  v-model="form.email"
                  type="email"
                  name="email"
                  class="w-full px-4 py-2"
                  placeholder="you@example.co.uk"
                  :disabled="isEmailLocked"
                />
              </div>

              <PrimaryButton
                type="submit"
                class="w-1/3 justify-center py-3"
              >
                Subscribe
              </PrimaryButton>
            </form>

            <InputError
              class="mt-2"
              :message="form.errors.email"
            />
          </div>

          <div
            v-else
            class="text-gray-500"
          >
            {{ hasSubscribedViaForm ? 'Thank you for subscribing!' : 'You are already subscribed to our newsletter.' }}
            <div
              v-if="!hasSubscribedViaForm"
              style="margin-top: 1rem;"
            >
              <Link
                :href="route('account.edit', { back: route('home') + '#newsletter' })"
                class="inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
              >
                Manage your subscription
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
