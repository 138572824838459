<script lang="ts" setup>
import { ref, inject } from 'vue'
import axios from 'axios'
import { ActivityResource } from '@/Types/generated'
import ActivityCard from '@/Components/Activities/ActivityCard.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface AllActivityResponse {
  data: ActivityResource[]
}

const props = defineProps<{
  page: { page: number, count: number },
  filters: object,
  search?: string
}>()

const hasLoaded = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const allActivities = ref<AllActivityResponse>({
  data: []
})

const loadActivities = async (isVisible: boolean) => {
  /*eslint-disable */

  if (isLoading.value || !isVisible) {
    return
  }

  isLoading.value = true

  try {
    const response = await axios.get(route('api.activities.index', { search: props.search, ...props.filters, page: props.page.page }))
    allActivities.value = response.data as AllActivityResponse
    hasLoaded.value = true
  } finally {
    isLoading.value = false
  }
  /* eslint-enable */
}
</script>

<template>
  <template v-if="hasLoaded">
    <ActivityCard
      v-for="(activity, index) in allActivities.data"
      :key="index"
      :activity="activity"
    />
  </template>
  <template v-else>
    <ActivityCard
      v-for="activityCard in props.page.count"
      :key="activityCard + '-' + props.page.page"
      v-observe-visibility="{
        callback: loadActivities,
        throttle: 300,
      }"
    />
  </template>
</template>
