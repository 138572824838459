<script setup lang="ts">
import { Head, Link, useForm } from '@inertiajs/vue3'
import { inject } from 'vue'
import { ExcursionResource } from '@/Types/generated'
import AppLayout from '@/Layouts/AppLayout.vue'
import { route as routeFn } from 'ziggy-js'
import { ActivityFormDTO } from '@/Types/activities'
import ActivityForm from '@/Pages/Activities/Partials/ActivityForm.vue'

const route = inject<typeof routeFn>('route')

const props = defineProps<{
  excursion?: ExcursionResource
}>()

const form = useForm<ActivityFormDTO>({
  excursion_id: props.excursion?.id,
  name: props.excursion?.name ?? '',
  description: props.excursion?.description ?? '',
  booking_details: props.excursion?.booking_details ?? '',
  price: props.excursion?.price ?? '',
  starts_at: '',
  ends_at: '',
  is_public: true,
  guests: [],
  image: null
})

function onSubmit () {
  form
    .transform((data) => ({
      ...data,
      guests: data.guests.map((guest) => guest.id)
    }))
    .post(route('activities.store'), {
      preserveScroll: true,
      onSuccess: () => {
        form.reset()
      }
    })
}

/* eslint-disable */
defineOptions({ layout: AppLayout })
/* eslint-enable */
</script>

<template>
  <div>
    <Head title="Create New Activity" />

    <div class="max-w-6xl mx-auto p-4">
      <div class="mb-4">
        <Link
          prefetch
          :href="route('activities.index')"
          class="text-purple-600 hover:underline flex items-center"
          aria-label="Back to Activities"
        >
          <i class="fas fa-arrow-left mr-1" />

          Back to Activities
        </Link>
      </div>

      <div class="bg-white rounded-lg overflow-hidden border border-gray-300 p-6">
        <h2 class="text-2xl font-semibold text-gray-700 mb-4">
          Add an activity
        </h2>

        <ActivityForm
          v-model="form"
          :initial-image-value="excursion?.image_media ?? null"
          submit-button-text="Create Activity"
          @submit="onSubmit"
        />
      </div>
    </div>
  </div>
</template>
