<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'
import { ContentSettings } from '@/Types/generated'

const page = usePage()

const contentSettings = computed<ContentSettings>(() => page.props.contentSettings)
</script>

<template>
  <div
    id="story"
    class="w-full mt-8 scroll-mt-20"
  >
    <div class="flex justify-center">
      <div class="max-w-5xl ">
        <div class="flex items-center justify-center flex-col text-center">
          <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
            Our Story
          </h2>
          <div class="flex items-center flex-row justify-center">
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
            <div class="flex items-center">
              <LavenderIcon />
            </div>
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
          </div>
        </div>

        <div class="text-center space-y-4 px-4 sm:px-0">
          <p
            class="leading-1 text-gray-500 font-sans"
            v-html="contentSettings.our_story_content"
          />
        </div>
      </div>
    </div>
  </div>
</template>
