<script lang="ts" setup>
import { usePage, Link, WhenVisible } from '@inertiajs/vue3'
import { ContentSettings, ExcursionResource, UserResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed, ref } from 'vue'
import ExcursionCard from '@/Components/Excursions/ExcursionCard.vue'
import ViewExcursionModal from '@/Components/Excursions/ViewExcursionModal.vue'
import CreateExcursionModal from '@/Components/Excursions/CreateExcursionModal.vue'

const page = usePage()

const excursions = computed<ExcursionResource[]|undefined>(() => page.props.excursions)
const user = computed<UserResource>(() => page.props.user)
const contentSettings = computed<ContentSettings>(() => page.props.contentSettings)

const viewExcursionModalOpen = ref<boolean>(false)
const createExcursionModalOpen = ref<boolean>(false)
const selectedExcursion = ref<ExcursionResource | null>(null)

const openModal = (excursion: ExcursionResource) => {
  selectedExcursion.value = excursion
  viewExcursionModalOpen.value = true
}
</script>

<template>
  <div
    id="excursions"
    class="w-full mt-8 scroll-mt-20"
  >
    <ViewExcursionModal
      :show="viewExcursionModalOpen"
      :excursion="selectedExcursion"
      @close="viewExcursionModalOpen = false"
    />

    <CreateExcursionModal
      v-if="user"
      :show="createExcursionModalOpen"
      @close="createExcursionModalOpen = false"
    />

    <div class="flex items-center justify-center flex-col text-center">
      <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
        Excursions
      </h2>
      <div class="flex items-center flex-row justify-center mb-8">
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
        <div class="flex items-center">
          <div class="flex items-center">
            <LavenderIcon />
          </div>
        </div>
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
      </div>
    </div>
    <p class="mt-4 text-gray-600 max-w-2xl">
      {{ contentSettings.excursions_content }}
    </p>

    <div class="max-w-6xl mx-auto px-4 sm:px-0">
      <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <WhenVisible
          data="excursions"
          :buffer="500"
        >
          <ExcursionCard
            v-for="excursion in excursions"
            :key="excursion.id"
            :excursion="excursion"
            @open-modal="openModal"
          />

          <template #fallback>
            <ExcursionCard
              v-for="index in 8"
              :key="index"
            />
          </template>
        </WhenVisible>
      </div>
    </div>

    <div class="flex justify-center space-x-4 mt-6">
      <button
        v-if="user"
        class="inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
        @click.prevent="createExcursionModalOpen = true"
      >
        Suggest an Excursion
      </button>
      <Link
        prefetch
        :href="route('excursions.index')"
        class="inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
      >
        View All
      </Link>
    </div>
  </div>
</template>
