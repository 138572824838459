<script lang="ts" setup>
import { computed, defineProps, inject } from 'vue'
import { Link } from '@inertiajs/vue3'
import { format } from 'date-fns'
import { MediaResource } from '@/Types/generated'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface DateInfo {
  label: string;
  date: string;
}

const props = defineProps<{media?: MediaResource; }>()

const isVideo = computed<boolean>(() => {
  return props.media?.mime_type.includes('video') ?? false
})

const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'dd/M/yyyy')
}

const dateInfo = computed<DateInfo | null>(() => {
  if (props.media?.taken_at) {
    return { label: 'Taken:', date: formatDate(props.media.taken_at) }
  } else if (props.media?.created_at) {
    return { label: 'Uploaded:', date: formatDate(props.media.created_at) }
  }
  return null
})

</script>

<template>
  <div class="relative cursor-pointer hover:opacity-75 transition-opacity duration-200">
    <Link
      v-if="props.media"
      prefetch="click"
      :href="route('media.show', {media: props.media.uuid})"
      preseve-scroll
      preserve-state
    >
      <div class="aspect-w-16 aspect-h-9 relative">
        <img
          :src="props.media.thumbnail_url ?? ''"
          alt=""
          class="w-full h-full object-cover"
        >
        <div
          v-if="isVideo"
          class="absolute inset-0 flex items-center justify-center"
        >
          <i class="fas fa-play-circle text-white text-4xl" />
        </div>
      </div>
      <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 text-white p-2 flex items-center justify-between text-sm">
        <div class="flex items-center flex-shrink-0">
          <img
            v-if="props.media.user && props.media.user.guest"
            :src="props.media.user.guest.avatar"
            alt="Avatar"
            class="w-6 h-6 rounded-full mr-2"
          >
          <span class="mr-2"><i class="fas fa-comments" /> {{ props.media.comments_count ?? 0 }}</span>
        </div>
        <div
          v-if="dateInfo"
          class="text-right whitespace-nowrap ml-2"
        >
          <span>{{ dateInfo.label }} {{ dateInfo.date }}</span>
        </div>
      </div>
    </Link>
    <div
      v-else
      class="aspect-w-16 aspect-h-9 bg-gray-200 animate-pulse"
    >
      <div class="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 text-white p-2 flex items-center">
        <span class="mr-2"><i class="fas fa-spinner fa-spin" /> Loading...</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.aspect-w-16 {
  aspect-ratio: 16 / 9;
}
.aspect-h-9 {
  aspect-ratio: 16 / 9;
}
</style>
