<script lang="ts" setup>
import { defineProps, onMounted, onUnmounted, ref } from 'vue'
import { Head } from '@inertiajs/vue3'
// import NavigationSection from '@/Sections/IndexPage/NavigationSection.vue'
import LetterSection from '@/Sections/IndexPage/LetterSection.vue'
import BannerSection from '@/Sections/IndexPage/BannerSection.vue'
import StorySection from '@/Sections/IndexPage/StorySection.vue'
import EventsSection from '@/Sections/IndexPage/EventsSection.vue'
import GuestsSection from '@/Sections/IndexPage/GuestsSection.vue'
import GallerySection from '@/Sections/IndexPage/GallerySection.vue'
import NewsletterSection from '@/Sections/IndexPage/NewsletterSection.vue'
import TodoSection from '@/Sections/IndexPage/TodoSection.vue'
import { useToastMessage } from '@/Composables/UseToasts'
import {
  FeatureSettings,
  LetterResource,
  UserResource
} from '@/Types/generated'
import BoardGamesSection from '@/Sections/IndexPage/BoardGamesSection.vue'
import BackToTopButton from '@/Components/UI/BackToTopButton.vue'
import GiftSection from '@/Sections/IndexPage/GiftSection.vue'
import MinecraftSection from '@/Sections/IndexPage/MinecraftSection.vue'
import ActivitySection from '@/Sections/ActivitySection.vue'
import ExcursionSection from '@/Sections/IndexPage/ExcursionSection.vue'
import AppLayout from '@/Layouts/AppLayout.vue'

const props = defineProps<{
  flash?: { message: string };
  features: FeatureSettings
  user?: UserResource
  letter?: LetterResource;
}>()

const contentRef = ref<HTMLElement | null>(null)

onUnmounted(() => {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
  sessionStorage.setItem('scrollPosition', scrollPosition.toString())
})

onMounted(() => {
  const savedPosition = sessionStorage.getItem('scrollPosition')
  if (savedPosition !== null) {
    setTimeout(() => {
      window.scrollTo(0, parseInt(savedPosition))
      sessionStorage.removeItem('scrollPosition')
    }, 0)
  }
})

/* eslint-disable */
defineOptions({ layout: AppLayout })
/* eslint-enable */

useToastMessage(props)
</script>

<template>
  <Head title="Hargrave Wedding" />
  <BannerSection />

  <div
    ref="contentRef"
    class="max-w-7xl mx-auto space-y-10 sm:space-y-24"
  >
    <LetterSection v-if="props.user && props.features?.letters_enabled && props.letter" />
    <StorySection />
    <EventsSection v-if="props.features?.events_enabled" />
    <GiftSection />
    <GuestsSection v-if="props.features?.guests_enabled" />
    <ExcursionSection v-if="props.features?.excursions_enabled" />
    <ActivitySection v-if="props.features?.activities_enabled" />
    <TodoSection v-if="props.features?.checklist_enabled && props.user" />
    <GallerySection v-if="props.features?.gallery_enabled" />
    <BoardGamesSection v-if="props.features?.board_games_enabled" />
    <MinecraftSection v-if="props.features?.minecraft_enabled" />
  </div>

  <NewsletterSection v-if="props.features?.newsletter_enabled" />

  <BackToTopButton />
</template>
