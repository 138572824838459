<script lang="ts" setup>
import { MediaLibraryAttachment } from 'media-library-pro-vue3-attachment'
import MultiSelect from '@/Components/UI/Inputs/MultiSelect.vue'
import CheckboxInput from '@/Components/UI/Inputs/CheckboxInput.vue'
import { ActivityFormDTO } from '@/Types/activities'
import { computed, ref, watch } from 'vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'

const emit = defineEmits(['submit', 'update:modelValue', 'change'])

const props = defineProps<{
  modelValue: ActivityFormDTO,
  initialImageValue: string | null,
  submitButtonText: string
}>()

const form = ref<ActivityFormDTO>(props.modelValue)
const hasUploadsInProgress = ref<boolean>(false)
const isReadyToSubmit = ref<boolean>(false)

watch(() => form.value, (value) => {
  emit('update:modelValue', value)
  emit('change', value)
})

watch(() => props.modelValue, (value) => {
  form.value = value
})

const canSubmit = computed<boolean>(() => {
  return !form.value.processing && !hasUploadsInProgress.value && isReadyToSubmit.value
})

const onSubmit = () => {
  emit('submit')
}
</script>
<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-4">
      <label
        for="name"
        class="block text-gray-700 font-medium mb-2"
      >Activity Name*</label>
      <input
        id="name"
        v-model="form.name"
        type="text"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        placeholder="Enter activity name"
        required
        aria-label="Activity Name"
      >
      <span
        v-if="form.errors.name"
        class="text-red-500 text-sm"
      >{{ form.errors.name }}</span>
    </div>

    <div class="mb-4">
      <label
        for="image"
        class="block text-gray-700 font-medium mb-2"
      >Image</label>

      <MediaLibraryAttachment
        v-model="form.image"
        name="guest.avatar"
        :validation-errors="form.errors"
        :validation-rules="{ accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/avif', 'image/webp'] }"
        :initial-value="initialImageValue"
        route-prefix="media"
        @change="form.image = $event"
        @is-ready-to-submit-change="isReadyToSubmit = $event"
        @has-uploads-in-progress-change="hasUploadsInProgress = $event"
      />
      <span
        v-if="form.errors.image"
        class="text-red-500 text-sm"
      >{{ form.errors.image }}</span>
    </div>

    <div class="mb-4">
      <label
        for="is_public"
        class="block text-gray-700 font-medium mb-2"
      >Public Activity</label>
      <CheckboxInput
        v-model="form.is_public"
        label="Public Activity"
        :checked="form.is_public"
      />
      <span
        v-if="form.errors.is_public"
        class="text-red-500 text-sm"
      >{{ form.errors.is_public }}</span>
    </div>

    <div
      v-if="!form.is_public"
      class="mb-4"
    >
      <label
        for="guests"
        class="block text-gray-700 font-medium mb-2"
      >Guests</label>

      <MultiSelect
        v-model="form.guests"
        multiple
        route-name="api.guests.index"
      />
      <span
        v-if="form.errors.guests"
        class="text-red-500 text-sm"
      >{{ form.errors.guests }}</span>
    </div>

    <div class="mb-4">
      <label
        for="description"
        class="block text-gray-700 font-medium mb-2"
      >Description*</label>
      <textarea
        id="description"
        v-model="form.description"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        placeholder="Enter activity description"
        rows="5"
        required
        aria-label="Activity Description"
      />
      <span
        v-if="form.errors.description"
        class="text-red-500 text-sm"
      >{{ form.errors.description }}</span>
    </div>

    <div class="mb-4">
      <label
        for="booking_details"
        class="block text-gray-700 font-medium mb-2"
      >Booking Details</label>
      <textarea
        id="booking_details"
        v-model="form.booking_details"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        placeholder="Enter booking details (optional)"
        rows="3"
        aria-label="Booking Details"
      />
      <span
        v-if="form.errors.booking_details"
        class="text-red-500 text-sm"
      >{{ form.errors.booking_details }}</span>
    </div>

    <div class="mb-4">
      <label
        for="price"
        class="block text-gray-700 font-medium mb-2"
      >Price</label>
      <input
        id="price"
        v-model="form.price"
        type="text"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        placeholder="Enter activity price (optional)"
        aria-label="Price"
      >
      <span
        v-if="form.errors.price"
        class="text-red-500 text-sm"
      >{{ form.errors.price }}</span>
    </div>

    <div class="mb-4">
      <label
        for="starts_at"
        class="block text-gray-700 font-medium mb-2"
      >Starts at</label>
      <input
        id="stars_at"
        v-model="form.starts_at"
        type="datetime-local"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        aria-label="Starts at"
      >
      <span
        v-if="form.errors.starts_at"
        class="text-red-500 text-sm"
      >{{ form.errors.starts_at }}</span>
    </div>

    <div class="mb-4">
      <label
        for="ends_at"
        class="block text-gray-700 font-medium mb-2"
      >Ends at</label>
      <input
        id="ends_at"
        v-model="form.ends_at"
        type="datetime-local"
        class="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        aria-label="Ends at"
      >
      <span
        v-if="form.errors.ends_at"
        class="text-red-500 text-sm"
      >{{ form.errors.ends_at }}</span>
    </div>

    <div class="flex justify-end">
      <PrimaryButton
        type="submit"
        :disabled="!canSubmit"
        :aria-label="submitButtonText"
      >
        {{ form.processing ? 'Saving...' : submitButtonText }}
      </PrimaryButton>
    </div>
  </form>
</template>
