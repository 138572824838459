<script lang="ts" setup>
import { usePage, Link, WhenVisible } from '@inertiajs/vue3'
import { BoardGameResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'
import BoardGameTile from '@/Components/BoardGames/BoardGameTile.vue'

const page = usePage()

const boardGames = computed<BoardGameResource[]|undefined>(() => page.props.boardGames)
</script>

<template>
  <div
    id="board-games"
    class="w-full mt-8 scroll-mt-20"
  >
    <div class="flex items-center justify-center flex-col text-center">
      <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
        Board Game Library
      </h2>
      <div class="flex items-center flex-row justify-center mb-8">
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
        <div class="flex items-center">
          <div class="flex items-center">
            <LavenderIcon />
          </div>
        </div>
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-0">
      <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <WhenVisible
          data="boardGames"
          :buffer="500"
        >
          <BoardGameTile
            v-for="boardGame in boardGames"
            :key="boardGame.id"
            :board-game="boardGame"
          />
        </WhenVisible>
      </div>
    </div>

    <div class="flex justify-center mt-2">
      <Link
        prefetch
        class="mt-4 inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
        :href="route('board-games.index')"
      >
        View All
      </Link>
    </div>
  </div>
</template>
