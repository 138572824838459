<script lang="ts" setup>
import { computed, ref } from 'vue'
import { usePage, Link } from '@inertiajs/vue3'
import { EventResource, UserResource } from '@/Types/generated'
import EventCard from '@/Components/Events/EventCard.vue'
import EventModal from '@/Components/Events/EventModal.vue'

const page = usePage()

const events = computed<EventResource[]>(() => page.props.events)
const user = computed<UserResource | null>(() => page.props.user)

const selectedEvent = ref<EventResource | null>(null)
const eventModalOpen = ref<boolean>(false)

const openModal = (event: EventResource) => {
  selectedEvent.value = event
  eventModalOpen.value = true
}
</script>

<template>
  <EventModal
    :show="eventModalOpen"
    :event="selectedEvent"
    @close="eventModalOpen = false"
  />
  <div
    id="events"
    class="w-full space-y-10 sm:space-y-0 mt-8 scroll-mt-20"
  >
    <EventCard
      v-for="(event, index) in events"
      :key="index"
      :event="event"
      :index="index"
      @open-modal="openModal"
    />

    <div
      v-if="!user"
      class="flex justify-center mt-2"
    >
      <Link
        prefetch
        class="mt-4 inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
        :href="route('login')"
      >
        Sign in for RSVP and to see more
      </Link>
    </div>
  </div>
</template>
