<script lang="ts" setup>
import { defineEmits, defineProps, inject, ref } from 'vue'
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { MediaLibraryAttachment } from 'media-library-pro-vue3-attachment'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import TextArea from '@/Components/UI/Inputs/TextArea.vue'
import { useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const emit = defineEmits(['close'])

const props = defineProps<{
  show: boolean
}>()

const form = useForm<{ name: string, image: string, description: string, booking_details: string, price: string }>({
  name: '',
  image: '',
  description: '',
  booking_details: '',
  price: ''
})

const hasUploadsInProgress = ref<boolean>(false)
const isReadyToSubmit = ref<boolean>(false)

const onClose = () => {
  emit('close')
}

const create = () => {
  form.post(route('excursions.store'), {
    preserveScroll: true,
    preserveState: true,
    onSuccess () {
      emit('close')
      form.reset()
    }
  })
}
</script>

<template>
  <DialogModal
    :show="props.show"
    @close="onClose"
  >
    <template #title>
      <h2 class="text-lg font-medium text-gray-800 font-serif">
        Suggest an Excursion
      </h2>
    </template>

    <template #content>
      <div>
        <InputLabel
          for="name"
          value="Name"
        />

        <TextInput
          id="name"
          v-model="form.name"
          class="mt-1 block w-full"
          required
        />

        <InputError
          class="mt-2"
          :message="form.errors.name"
        />
      </div>

      <div class="mt-2">
        <InputLabel
          for="image"
          value="Image"
        />

        <MediaLibraryAttachment
          v-model="form.image"
          name="image"
          :validation-errors="form.errors"
          :validation-rules="{ accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/avif', 'image/webp'] }"
          route-prefix="media"
          @change="form.image = $event"
          @is-ready-to-submit-change="isReadyToSubmit = $event"
          @has-uploads-in-progress-change="hasUploadsInProgress = $event"
        />

        <InputError
          class="mt-2"
          :message="form.errors.image"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="description"
          value="Description"
        />

        <TextArea
          id="description"
          v-model="form.description"
          class="mt-1 block w-full"
          required
          autofocus
        />

        <InputError
          class="mt-2"
          :message="form.errors.description"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="booking_details"
          value="Booking Details"
        />

        <TextArea
          id="booking_details"
          v-model="form.booking_details"
          class="mt-1 block w-full"
        />

        <InputError
          class="mt-2"
          :message="form.errors.booking_details"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="price"
          value="Price"
        />

        <TextInput
          id="price"
          v-model="form.price"
          class="mt-1 block w-full"
        />

        <InputError
          class="mt-2"
          :message="form.errors.price"
        />
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
      <button
        type="button"
        class="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        :disabled="!isReadyToSubmit || hasUploadsInProgress || form.processing"
        @click="create"
      >
        Save
      </button>
    </template>
  </DialogModal>
</template>

<style scoped>
img {
  object-fit: cover;
}
</style>
