<script lang="ts" setup>
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import { Head, useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'
import { inject } from 'vue'

const route = inject<typeof routeFn>('route')

interface Form {
  password: string;
}

const props = defineProps<{background: string|null|undefined}>()

const form = useForm<Form>({
  password: ''
})

const submit = (): void => {
  form.post(route('password.confirm'), {
    onFinish: () => form.reset()
  })
}
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="Confirm Password | Hargrave Wedding" />

    <div class="mb-4 text-sm text-gray-600">
      This is a secure area of the application. Please confirm your password before continuing.
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel
          for="password"
          value="Password"
        />
        <TextInput
          id="password"
          v-model="form.password"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="current-password"
          autofocus
        />
        <InputError
          class="mt-2"
          :message="form.errors.password"
        />
      </div>

      <div class="flex justify-end mt-4">
        <PrimaryButton
          class="ms-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Confirm
        </PrimaryButton>
      </div>
    </form>
  </GuestLayout>
</template>
