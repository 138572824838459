<script lang="ts" setup>
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import { Head, Link, useForm, usePage } from '@inertiajs/vue3'
import Checkbox from '@/Components/UI/Inputs/CheckboxInput.vue'
import { InviteResource } from '@/Types/generated'
import { computed, inject } from 'vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface Form {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  has_newsletter: boolean;
}

const props = defineProps<{background: string|null|undefined}>()
const page = usePage()

const invite = computed<InviteResource>(() => page.props.invite)

const form = useForm<Form>({
  name: invite.value.name ?? '',
  email: invite.value.email ?? '',
  password: '',
  password_confirmation: '',
  has_newsletter: false
})

const submit = (): void => {
  form.post(route('invite.accept', { invite: invite.value.id }), {
    onFinish: () => form.reset('password', 'password_confirmation')
  })
}
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="You're Invited! | Hargrave Wedding" />

    <form @submit.prevent="submit">
      <div>
        <InputLabel
          for="name"
          value="Name"
        />

        <TextInput
          id="name"
          v-model="form.name"
          type="text"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="name"
        />

        <InputError
          class="mt-2"
          :message="form.errors.name"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="email"
          value="Email"
        />

        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="mt-1 block w-full"
          autocomplete="username"
        />

        <InputError
          class="mt-2"
          :message="form.errors.email"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="password"
          value="Password"
        />

        <TextInput
          id="password"
          v-model="form.password"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="new-password"
        />

        <InputError
          class="mt-2"
          :message="form.errors.password"
        />
      </div>

      <div class="mt-4">
        <InputLabel
          for="password_confirmation"
          value="Confirm Password"
        />

        <TextInput
          id="password_confirmation"
          v-model="form.password_confirmation"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="new-password"
        />

        <InputError
          class="mt-2"
          :message="form.errors.password_confirmation"
        />
      </div>

      <div class="block mt-4">
        <label class="flex items-center">
          <Checkbox
            v-model:checked="form.has_newsletter"
            name="has_newsletter"
          />
          <span class="ms-2 text-sm text-gray-600">Subscribe to newsletter</span>
        </label>
      </div>

      <div class="flex items-center justify-end mt-4">
        <Link
          :href="route('login')"
          class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Already registered?
        </Link>

        <PrimaryButton
          class="ms-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Register
        </PrimaryButton>
      </div>
    </form>
  </GuestLayout>
</template>
