<script lang="ts" setup>
import { NotificationResource } from '@/Types/generated'
import { format } from 'date-fns'
import { ref, watch } from 'vue'

const emit = defineEmits(['click'])

const props = withDefaults(defineProps<{
  read?: boolean
  notification?: NotificationResource
}>(), {
  read: false
})

const data = ref<NotificationResource|undefined>(props.notification)

const formatDate = (date: string) => {
  return format(new Date(date), 'MMMM dd, yyyy HH:mm')
}

const onClick = () => {
  if (!data.value) {
    return
  }

  emit('click', props.notification)
  data.value.read_at = new Date().toISOString()
}

watch(() => props.notification, (newNotification) => {
  data.value = newNotification
})
</script>

<template>
  <a
    v-if="data"
    href="#"
    :class="[
      'block px-4 py-2 text-gray-700 text-sm',
      !data.read_at && !props.read ? 'bg-purple-100 font-bold' : 'hover:bg-gray-100'
    ]"
    @click.prevent="onClick"
  >
    <div>
      <span>{{ data.message }}</span>
      <div class="text-gray-500 text-xs mt-1">
        {{ formatDate(data.created_at) }}
      </div>
    </div>
  </a>

  <div
    v-else
    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 text-sm"
  >
    Loading...
  </div>
</template>
