<script lang="ts" setup>
import { Head, router, Link, usePage } from '@inertiajs/vue3'
import { computed, inject, onMounted, ref, watch } from 'vue'
import BackToTopButton from '@/Components/UI/BackToTopButton.vue'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { ExcursionFilters } from '@/Types/excursions'
import { debounce } from 'lodash'
import { UserResource } from '@/Types/generated'
import { useToastMessage } from '@/Composables/UseToasts'
import ActivityCardPage from '@/Pages/Activities/Partials/ActivityCardPage.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface Page {
  page: number
  count: number
}

const props = defineProps<{ activityCount: number, perPage: number, flash: {message: string} }>()

const page = usePage()

const user = computed<UserResource>(() => page.props.user)

const activityContent = computed<string>(() => page.props.activityContent)

const resetKey = ref<number>(0)

const filters = ref<ExcursionFilters>({})

const search = ref<string | null>(null)

const activityPages = computed<Page[]>(() => {
  const pages: Page[] = []

  const totalPages = Math.ceil(props.activityCount / props.perPage)
  const remainder = props.activityCount % props.perPage ? props.activityCount % props.perPage : props.perPage

  for (let i = 1; i <= totalPages; i++) {
    pages.push({
      page: i,
      count: i === totalPages ? remainder : props.perPage
    } as Page)
  }

  return pages
})

const reset = (): void => {
  // TODO: Eslint
  /* eslint-disable */
  const currentRoute = route().current()
  router.get(route(currentRoute?.toString() ?? ''), {
    filters: filters.value,
    search: search.value
  }, {
    onSuccess: () => {
      resetKey.value++
    },
    preserveState: true,
    preserveScroll: true,
  })
  /* eslint-enable */
}

/* eslint-disable */
const debouncedReset = debounce<void>(reset, 300)

watch(filters, () => {
  debouncedReset()
}, { deep: true })

watch(search, () => {
  debouncedReset()
})
/* eslint-enable */

onMounted(() => {
  // TODO
  /* eslint-disable */
  const params = route().params

  if (params.search) {
    search.value = params.search
  }
  /* eslint-enable */
})

/* eslint-disable */
defineOptions({ layout: AppLayout })
/* eslint-enable */

useToastMessage(props)
</script>

<template>
  <div
    id="activities"
    class="w-full mt-8 scroll-mt-20"
  >
    <Head title="Activities | Hargrave Wedding" />

    <div class="flex flex-col sm:flex-row max-w-7xl mx-auto">
      <div class="hidden sm:block w-1/4 bg-gray-100 p-4 rounded-lg sticky top-24 h-[90vh] overflow-auto mr-6">
        <div class="mb-6">
          <Link
            v-if="user"
            prefetch
            class="w-full block text-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            :href="route('activities.create')"
          >
            Create an activity
          </Link>
        </div>

        <div class="mb-8">
          <div class="mb-4">
            <label class="block text-gray-700 font-semibold mb-2">Search</label>
            <input
              v-model="search"
              class="block w-full bg-white border border-gray-300 rounded px-3 py-2"
              placeholder="Search activities..."
            >
          </div>
        </div>
      </div>

      <div class="w-full sm:w-3/4">
        <div class="flex items-center justify-center flex-col text-center mb-8">
          <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
            Activities
          </h2>
          <div class="flex items-center flex-row justify-center">
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
            <LavenderIcon class="h-6 w-6 fill-purple-600" />
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
          </div>
          <p class="mt-4 text-gray-600 max-w-2xl">
            {{ activityContent }}
          </p>
        </div>
        <div class="grid grid-cols-1 gap-4 m-4 pb-20 sm:pb-4">
          <ActivityCardPage
            v-for="(activityPage, index) in activityPages"
            :key="index+'-'+resetKey"
            :page="activityPage"
            :filters="filters"
            :search="search"
          />
        </div>
      </div>
    </div>

    <div class="fixed bottom-0 left-0 right-0 bg-gray-100 border-t border-gray-300 p-2 sm:hidden z-10">
      <div class="flex justify-between items-end">
        <div class="w-full px-1">
          <label class="block text-gray-700 font-semibold mb-1 text-xs">Search</label>
          <input
            v-model="search"
            class="h-9 block w-full bg-white border border-gray-300 rounded px-3 py-2 text-sm"
            placeholder="Search activities..."
          >
        </div>
      </div>
    </div>

    <BackToTopButton />
  </div>
</template>

<style scoped>
</style>
