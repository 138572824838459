<script setup lang="ts">
import ApplicationLogo from '@/Components/UI/ApplicationLogo.vue'
import { Link } from '@inertiajs/vue3'

const props = defineProps<{background: string|null|undefined}>()
</script>

<template>
  <div
    :style="{ backgroundImage: props.background ? `url(${props.background})` : '', backgroundSize: 'cover', backgroundPosition: 'center' }"
    class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100"
  >
    <div>
      <Link href="/">
        <ApplicationLogo />
      </Link>
    </div>

    <div
      class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg"
    >
      <slot />
    </div>
  </div>
</template>
