import { ref } from 'vue'
import mitt, { Emitter } from 'mitt'

interface Events {
    [key: string | symbol]: unknown;
    confirm: undefined;
}

const emitter: Emitter<Events> = mitt<Events>()
const showModal = ref(false)
const resource = ref('')

export function useConfirmationModal () {
  const openModal = (resourceName: string) => {
    resource.value = resourceName
    showModal.value = true
  }

  const closeModal = () => {
    showModal.value = false
  }

  const confirmModal = () => {
    emitter.emit('confirm')
    closeModal()
  }

  return {
    showModal,
    resource,
    openModal,
    closeModal,
    confirmModal,
    emitter
  }
}
