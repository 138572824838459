<script lang="ts" setup>
import { ref, inject } from 'vue'
import { NotificationResource } from '@/Types/generated'
import axios from 'axios'
import Notification from '@/Components/Notifications/Notification.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const emit = defineEmits(['click'])

interface AllNotificationResponse {
  data: NotificationResource[];
}

const props = withDefaults(defineProps<{
  allRead: boolean;
  page: { page: number; count: number };
}>(), {
  allRead: false
})

const hasLoaded = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const allNotifications = ref<AllNotificationResponse>({
  data: []
})

const loadNotifications = async (isVisible: boolean) => {
  if (isLoading.value || !isVisible || hasLoaded.value) {
    return
  }

  isLoading.value = true

  try {
    const response = await axios.get(
      route('api.notifications.index', {
        page: props.page.page
      })
    )
    allNotifications.value = response.data as AllNotificationResponse
    hasLoaded.value = true
  } catch (error) {
    console.error('Error loading comments:', error)
  } finally {
    isLoading.value = false
  }
}

const onClick = (notification: NotificationResource) => {
  emit('click', notification)
}
</script>

<template>
  <template v-if="hasLoaded">
    <Notification
      v-for="notification in allNotifications.data"
      :key="notification.id ?? notification.created_at"
      :notification="notification"
      :read="props.allRead"
      @click="onClick"
    />
  </template>

  <template v-else>
    <Notification
      v-for="notification in props.page.count"
      :key="notification + '-' + props.page.page"
      v-observe-visibility="{
        callback: loadNotifications,
        throttle: 300,
      }"
    />
  </template>
</template>
