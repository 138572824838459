<script lang="ts" setup>
import MediaCard from '@/Components/Media/MediaCard.vue'
import { ref, inject } from 'vue'
import axios from 'axios'
import { MediaResource } from '@/Types/generated'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface AllMediaResponse {
  data: MediaResource[]
}

const props = defineProps<{ page: { page: number, count: number } }>()

const hasLoaded = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const allMedia = ref<AllMediaResponse>({
  data: []
})

const loadMedia = async (isVisible: boolean) => {
  if (isLoading.value || !isVisible) {
    return
  }

  isLoading.value = true

  try {
    const response = await axios.get(route('api.media.index', { page: props.page.page }))
    allMedia.value = response.data as AllMediaResponse
    hasLoaded.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <template v-if="hasLoaded">
    <MediaCard
      v-for="(media, index) in allMedia.data"
      :key="index"
      :media="media"
    />
  </template>
  <template v-else>
    <MediaCard
      v-for="mediaCard in props.page.count"
      :key="mediaCard + '-' + props.page.page"
      v-observe-visibility="{
        callback: loadMedia,
        throttle: 300,
      }"
    />
  </template>
</template>
