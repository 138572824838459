<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'
import { ContentSettings } from '@/Types/generated'

const page = usePage()

const contentSettings = computed<ContentSettings>(() => page.props.contentSettings)
</script>

<template>
  <div
    id="minecraft"
    class="w-full mt-8 scroll-mt-20"
  >
    <div class="flex justify-center">
      <div class="max-w-5xl">
        <div class="flex items-center justify-center flex-col text-center">
          <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
            Minecraft
          </h2>
          <div class="flex items-center flex-row justify-center">
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
            <div class="flex items-center">
              <LavenderIcon />
            </div>
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
          </div>
        </div>

        <div class="text-center space-y-4 px-4 sm:px-0">
          <p
            class="leading-1 text-gray-500 font-sans"
            v-html="contentSettings.minecraft_content"
          />

          <div class="flex justify-center space-x-4 mt-6">
            <a
              href="https://minecraft.hargrave.wedding"
              target="_blank"
              class="inline-flex items-center px-4 py-2 bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            >
              Map
            </a>
            <a
              href="https://nohopelauncher.andyh.app"
              target="_blank"
              class="inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            >
              How to join
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
