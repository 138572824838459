<script lang="ts" setup>
import { computed, inject } from 'vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import { Head, Link, useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const props = defineProps<{status: string|null, background: string|null|undefined}>()

const form = useForm({})

const verificationLinkSent = computed(() => props.status === 'verification-link-sent')

const submit = (): void => {
  form.post(route('verification.send'))
}
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="Email Verification | Hargrave Wedding" />

    <div class="mb-4 text-sm text-gray-600">
      Thanks for signing up! Before getting started, could you verify your email address by clicking on the link
      we just emailed to you? If you didn't receive the email, we will gladly send you another.
    </div>

    <div
      v-if="verificationLinkSent"
      class="mb-4 font-medium text-sm text-green-600"
    >
      A new verification link has been sent to the email address you provided during registration.
    </div>

    <form @submit.prevent="submit">
      <div class="mt-4 flex items-center justify-between">
        <PrimaryButton
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Resend Verification Email
        </PrimaryButton>

        <Link
          :href="route('logout')"
          method="post"
          as="button"
          class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Log Out
        </Link>
      </div>
    </form>
  </GuestLayout>
</template>
