<script setup lang="ts">
import { onMounted, ref } from 'vue'

const model = defineModel({
  type: String,
  required: true
})

const input = ref<null | HTMLInputElement>(null)

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value.focus()
  }
})

defineExpose({
  focus: () => {
    if (input.value) {
      input.value.focus()
    }
  }
})
</script>

<template>
  <textarea
    ref="input"
    v-model="model"
    class="border-gray-300 focus:border-purple-500 focus:ring-purple-500 rounded-md shadow-sm"
  />
</template>
