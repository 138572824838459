<script setup lang="ts">
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { defineEmits, defineProps } from 'vue'
import { TodoResource } from '@/Types/generated'

const props = defineProps<{show: boolean, todo: TodoResource}>()

const emit = defineEmits(['close'])

const onClose = () => {
  emit('close')
}

const processHtml = (html: string|null): string => {
  if (!html) {
    return ''
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const links = Array.from(doc.getElementsByTagName('a'))

  links.forEach((link: HTMLAnchorElement) => {
    link.classList.add('text-purple-600', 'hover:text-purple-800')
  })

  return doc.body.innerHTML
}
</script>

<template>
  <DialogModal :show="show">
    <template #title>
      <h2 class="text-lg font-medium text-gray-800">
        {{ props.todo.name }}
      </h2>
    </template>
    <template #content>
      <div
        v-html="processHtml(props.todo?.details)"
      />
    </template>
    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
    </template>
  </DialogModal>
</template>
