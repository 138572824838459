<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
  checked: {
    type: [Array, Boolean],
    required: true
  },
  value: {
    type: String,
    default: null
  }
})

const proxyChecked = computed({
  get () {
    return props.checked
  },

  set (val) {
    emit('update:checked', val)
  }
})
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="rounded border-purple-300 text-purple-600 shadow-sm focus:ring-purple-500"
  >
</template>
