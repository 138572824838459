<script lang="ts" setup>
import { ActivityAttendanceEnum, ActivityGuestResource } from '@/Types/generated'
import { ref } from 'vue'

const props = defineProps<{
  guests: ActivityGuestResource[]
}>()

const hoveredGuestId = ref<number | null>(null)

const showGuestName = (guestId: number) => {
  hoveredGuestId.value = guestId
}

const hideGuestName = () => {
  hoveredGuestId.value = null
}
</script>

<template>
  <div class="flex">
    <div
      v-for="(guest, index) in props.guests"
      :key="guest.id"
      class="relative inline-block"
      :style="{ marginLeft: index !== 0 ? '-8px' : '0' }"
      @mouseenter="showGuestName(guest.id)"
      @mouseleave="hideGuestName"
    >
      <div class="relative w-10 h-10">
        <img
          :src="guest.avatar"
          alt="Avatar"
          class="w-full h-full rounded-full border-2 border-white"
        >
        <div
          v-if="guest.attendance !== ActivityAttendanceEnum.NotResponded"
          class="absolute bottom-0 right-0 w-4 h-4 rounded-full flex items-center justify-center z-10 border-2 border-white"
          :class="guest.attendance === ActivityAttendanceEnum.Attending ? 'bg-green-500' : 'bg-red-500'"
        >
          <i
            :class="guest.attendance === ActivityAttendanceEnum.Attending ? 'fas fa-check' : 'fas fa-times'"
            class="text-xs text-white"
          />
        </div>
      </div>

      <div
        v-if="hoveredGuestId === guest.id"
        class="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg whitespace-nowrap z-20"
      >
        {{ guest.name }}
      </div>
    </div>
  </div>
</template>
