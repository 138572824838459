import { watch } from 'vue'
import { useConfirmationModal } from '@/Composables/UseConfirmationModal'

class ModalCancelledError extends Error {
  constructor () {
    super('Modal was cancelled')
    this.name = 'ModalCancelledError'
  }
}

export function makeConfirmationModal (resourceName: string): Promise<void> {
  const { openModal, showModal, emitter } = useConfirmationModal()

  return new Promise<void>((resolve, reject) => {
    openModal(resourceName)

    const unwatch = watch(showModal, (newValue) => {
      if (!newValue) {
        unwatch()
        emitter.off('confirm', handleConfirm)
        reject(new ModalCancelledError())
      }
    })

    const handleConfirm = () => {
      unwatch()
      resolve()
    }

    emitter.on('confirm', handleConfirm)
  })
}

export { useConfirmationModal }
