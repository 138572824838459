<script lang="ts" setup>
import { BoardGameResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import BoardGameTile from '@/Components/BoardGames/BoardGameTile.vue'
import { Head } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import BackToTopButton from '@/Components/UI/BackToTopButton.vue'
import AppLayout from '@/Layouts/AppLayout.vue'

const props = defineProps<{ boardGames: BoardGameResource[] }>()

const filters = ref<{ playerCount: number | null, playTime: number | null }>({
  playerCount: null,
  playTime: null
})

const filteredBoardGames = computed(() => {
  return props.boardGames.filter((boardGame) => {
    if (filters.value.playerCount && (boardGame.max_players < filters.value.playerCount || boardGame.min_players > filters.value.playerCount)) {
      return false
    }

    if (filters.value.playTime && (boardGame.max_playing_time < filters.value.playTime || boardGame.min_playing_time > filters.value.playTime)) {
      return false
    }

    return true
  })
})

const playerCountOptions = computed<number[]>(() => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
})

const playTimeOptions = computed<number[]>(() => {
  return Array.from(new Set(props.boardGames.map((boardGame) => boardGame.max_playing_time))).sort((a, b) => a - b)
})

watch(
  () => filters.value,
  () => {
    window.scrollTo(0, 0)
  },
  { deep: true }
)

/* eslint-disable */
defineOptions({ layout: AppLayout })
/* eslint-enable */
</script>

<template>
  <div
    id="board-games"
    class="w-full mt-8 scroll-mt-20"
  >
    <Head title="Board Games | Hargrave Wedding" />

    <div class="flex flex-col sm:flex-row max-w-7xl mx-auto">
      <div class="hidden sm:block w-1/4 bg-gray-100 p-4 rounded-lg sticky top-24 h-[90vh] overflow-auto mr-6">
        <div class="mb-8">
          <div class="mb-4">
            <label class="block text-gray-700 font-semibold mb-2">Player Count</label>
            <select
              v-model="filters.playerCount"
              class="block w-full bg-white border border-gray-300 rounded px-3 py-2"
            >
              <option :value="null">
                All
              </option>
              <option
                v-for="n in playerCountOptions"
                :key="n"
                :value="n"
              >
                {{ n == 10 ? n+'+' : n }} {{ n == 1 ? 'Player' : 'Players' }}
              </option>
            </select>
          </div>

          <div class="mb-4">
            <label class="block text-gray-700 font-semibold mb-2">Play Time</label>
            <select
              v-model="filters.playTime"
              class="block w-full bg-white border border-gray-300 rounded px-3 py-2"
            >
              <option :value="null">
                All
              </option>
              <option
                v-for="n in playTimeOptions"
                :key="n"
                :value="n"
              >
                {{ n }} minutes
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="w-full sm:w-3/4">
        <div class="flex items-center justify-center flex-col text-center mb-8">
          <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
            Board Game Library
          </h2>
          <div class="flex items-center flex-row justify-center">
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
            <LavenderIcon class="h-6 w-6 fill-purple-600" />
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 m-4 pb-20 sm:pb-4">
          <BoardGameTile
            v-for="boardGame in filteredBoardGames"
            :key="boardGame.id"
            :board-game="boardGame"
          />
        </div>
      </div>
    </div>

    <div class="fixed bottom-0 left-0 right-0 bg-gray-100 border-t border-gray-300 p-2 sm:hidden z-10">
      <div class="flex justify-between items-end gap-2">
        <div class="flex-1 px-1">
          <label class="block text-gray-700 font-semibold mb-1 text-xs">Players</label>
          <select
            v-model="filters.playerCount"
            class="h-9 block w-full bg-white border border-gray-300 rounded px-3 py-2 text-sm"
          >
            <option :value="null">
              All
            </option>
            <option
              v-for="n in playerCountOptions"
              :key="n"
              :value="n"
            >
              {{ n == 10 ? n + '+' : n }}
            </option>
          </select>
        </div>
        <div class="flex-1 px-1">
          <label class="block text-gray-700 font-semibold mb-1 text-xs">Time</label>
          <select
            v-model="filters.playTime"
            class="h-9 block w-full bg-white border border-gray-300 rounded px-3 py-2 text-sm"
          >
            <option :value="null">
              All
            </option>
            <option
              v-for="n in playTimeOptions"
              :key="n"
              :value="n"
            >
              {{ n }} mins
            </option>
          </select>
        </div>
      </div>
    </div>

    <BackToTopButton />
  </div>
</template>

<style scoped>
</style>
