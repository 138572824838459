<script setup lang="ts">
import { defineProps, inject, computed } from 'vue'
import { Head, Link, router, useForm } from '@inertiajs/vue3'
import { ActivityAttendanceEnum, ActivityResource, Commentable, UserResource } from '@/Types/generated.js'
import CommentsArea from '@/Components/Comments/CommentsArea.vue'
import ConfirmationModal from '@/Components/UI/Modals/ConfirmationModal.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import SecondaryButton from '@/Components/UI/Buttons/SecondaryButton.vue'
import { route as routeFn } from 'ziggy-js'
import { useToastMessage } from '@/Composables/UseToasts'
import { makeConfirmationModal } from '@/Helpers/ConfirmationModalHelper'
import ActivityGuests from '@/Components/Activities/ActivityGuests.vue'
import CalendarLinksDropdown from '@/Components/Activities/CalendarLinksDropdown.vue'

const route = inject<typeof routeFn>('route')

const props = defineProps<{
  activity: ActivityResource,
  user?: UserResource
  flash: {message: string}
}>()

const form = useForm<{ attendance: string; }>({
  attendance: props.activity.attendance as string
})

const updateAttendance = (attendance: ActivityAttendanceEnum) => {
  form.attendance = attendance as string

  form.post(route('activities.attendance.store', { activity: props.activity.id }), {
    only: ['activity', 'flash'],
    preserveScroll: true,
    preserveState: true
  })
}

const onDelete = () => {
  if (!props.activity.can?.delete) return

  makeConfirmationModal('activity')
    .then(() => {
      router.delete(route('activities.destroy', { activity: props.activity.id }))
    }).catch(() => {
    // Do nothing
    })
}

useToastMessage(props)

/* eslint-disable */
defineOptions({ layout: AppLayout })
/* eslint-enable */

const isPast = computed(() => {
  if (!props.activity.starts_at) return false

  const startDate = new Date(props.activity.starts_at)
  const now = new Date()
  return startDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)
})

const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return 'N/A'
  const date = new Date(dateStr)
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const formatTime = (timeStr: string | null): string => {
  if (!timeStr) return 'N/A'

  const date = new Date(timeStr)

  if (isNaN(date.getTime())) {
    return 'Invalid Time'
  }

  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}
</script>

<template>
  <div>
    <ConfirmationModal />
    <Head :title="activity.name + ' | Hargrave Wedding'" />

    <div class="max-w-6xl mx-auto p-4">
      <div class="mb-4 flex justify-between items-center relative">
        <Link
          prefetch
          :href="route('activities.index')"
          class="text-purple-600 hover:underline flex items-center"
          aria-label="Back to Activities"
        >
          <i class="fas fa-arrow-left mr-1" />
          Back to Activities
        </Link>

        <div class="flex space-x-4 items-center">
          <Link
            v-if="activity.can?.update"
            :href="route('activities.edit', { activity: props.activity.id })"
            class="text-purple-500 hover:text-purple-700 transition-colors duration-200"
            aria-label="Edit Activity"
          >
            <i class="fas fa-edit" />
          </Link>

          <button
            v-if="activity.can?.delete"
            class="text-red-500 hover:text-red-700 transition-colors duration-200 focus:outline-none"
            aria-label="Delete Activity"
            @click="onDelete"
          >
            <i class="fas fa-trash-alt" />
          </button>

          <CalendarLinksDropdown
            v-if="activity.calendar_links"
            :calendar-links="activity.calendar_links"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden border border-gray-300 mb-6 relative">
        <div
          v-if="isPast"
          class="absolute inset-0 bg-white opacity-25 pointer-events-none"
        />

        <div
          v-if="props.activity.image"
          class="md:w-1/2 bg-cover bg-center h-72 md:h-auto"
          :style="{ backgroundImage: `url(${props.activity.image})` }"
          aria-label="Activity Image"
        />
        <div
          v-else
          class="md:w-1/2 bg-gray-200 flex items-center justify-center h-64 md:h-auto"
        >
          <span class="text-gray-500">No Image Available</span>
        </div>

        <div class="p-6 md:w-1/2 flex flex-col justify-center relative">
          <h1 class="text-3xl font-bold text-gray-800 mb-4">
            {{ props.activity.name }}
          </h1>

          <div
            v-if="isPast"
            class="mb-2"
          >
            <span class="bg-red-500 text-white text-xs px-2 py-1 rounded">Past Activity</span>
          </div>

          <div class="flex items-center text-gray-600 text-sm mb-4">
            <div class="flex items-center mr-6">
              <i
                class="fas fa-calendar-alt mr-2 text-purple-600"
                aria-hidden="true"
              />
              <span>{{ formatDate(activity.starts_at) }}</span>
            </div>
            <div class="flex items-center mr-6">
              <i
                class="fas fa-clock mr-2 text-purple-600"
                aria-hidden="true"
              />
              <span>{{ formatTime(activity.starts_at) }}</span>
            </div>
            <div
              v-if="activity.price"
              class="flex items-center mr-6"
            >
              <i
                class="fas fa-tag mr-2 text-purple-600"
                aria-hidden="true"
              />
              <span>{{ activity.price }}</span>
            </div>
          </div>

          <ActivityGuests :guests="activity.guests" />

          <PrimaryButton
            v-if="activity.can.update_attendance && activity.attendance === ActivityAttendanceEnum.NotResponded && !isPast"
            :disabled="form.processing"
            aria-label="Mark as Going"
            class="mt-4"
            @click="updateAttendance(ActivityAttendanceEnum.Attending)"
          >
            I am going
          </PrimaryButton>
          <SecondaryButton
            v-if="activity.can.update_attendance && activity.attendance === ActivityAttendanceEnum.NotResponded && !isPast"
            :disabled="form.processing"
            aria-label="Mark as Not Going"
            class="mt-2"
            @click="updateAttendance(ActivityAttendanceEnum.NotAttending)"
          >
            I can't make it
          </SecondaryButton>

          <div
            v-if="activity.attendance === ActivityAttendanceEnum.Attending"
            class="text-green-600 mt-4 mb-2"
          >
            {{ isPast ? 'You went to this activity' : 'You are going to this activity' }}
          </div>

          <div
            v-if="activity.attendance === ActivityAttendanceEnum.NotAttending"
            class="text-red-600 mt-4 mb-2"
          >
            {{ isPast ? 'You didn\'t go to this activity' : 'You are not going to this activity' }}
          </div>
          <SecondaryButton
            v-if="activity.can.update_attendance && activity.attendance !== ActivityAttendanceEnum.NotResponded && !isPast"
            @click="updateAttendance(ActivityAttendanceEnum.NotResponded)"
          >
            Change your response
          </SecondaryButton>
        </div>
      </div>

      <div class="bg-white rounded-lg overflow-hidden border border-gray-300 p-6 mb-6">
        <h2 class="text-2xl font-semibold text-gray-700 mb-4">
          Description
        </h2>
        <p class="text-gray-600 mb-6">
          {{ activity.description }}
        </p>

        <h2
          v-if="activity.booking_details"
          class="text-2xl font-semibold text-gray-700 mb-4"
        >
          Booking Details
        </h2>
        <p
          v-if="activity.booking_details"
          class="text-gray-600"
        >
          {{ activity.booking_details }}
        </p>
      </div>

      <CommentsArea
        :commentable-type="Commentable.Activity"
        :commentable-id="activity.id"
        :comment-count="activity.comments_count ?? 0"
      />
    </div>
  </div>
</template>

<style scoped>
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.activity-content {
  transition: all 0.3s ease-in-out;
}
</style>
