<script lang="ts" setup>
import { defineProps, ref, computed, defineEmits, onMounted } from 'vue'
import { ExcursionResource } from '@/Types/generated'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

const props = defineProps<{
  excursion?: ExcursionResource
}>()

const { excursion } = props

const emit = defineEmits(['open-modal'])

const descriptionExpanded = ref(false)
const bookingDetailsExpanded = ref(false)

const truncatedDescription = computed(() => {
  const description = excursion?.description ?? ''
  return description.length > 100 ? description.slice(0, 100) : description
})

const truncatedBookingDetails = computed(() => {
  const details = excursion?.booking_details ?? ''
  return details.length > 100 ? details.slice(0, 100) : details
})

const isBookingDetailsLink = computed(() => {
  const details = excursion?.booking_details
  if (!details) return false
  try {
    // eslint-disable-next-line no-new
    new URL(details)
    return true
  } catch (_) {
    return false
  }
})

const onView = () => {
  emit('open-modal', excursion)

  const avatar = document.querySelector('#avatar-tooltip-' + excursion.id)

  if (avatar) {
    const instance = tippy(avatar)
    instance.hide()
  }
}

/*eslint-disable */
onMounted(() => {
  if (excursion?.suggested_by) {
    const avatar = document.querySelector('#avatar-tooltip-' + excursion.id)
    if (avatar) {
      tippy(avatar, {
        content: `Suggested by: ${excursion.suggested_by.name}`,
        placement: 'top',
        theme: 'light-border',
        arrow: true
      })
    }
  }
})
/* eslint-enable */
</script>

<template>
  <div
    class="w-full bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300 transition-transform transform hover:scale-105 cursor-pointer flex flex-col"
    v-bind="$attrs"
    @click="excursion && onView()"
  >
    <div
      v-if="excursion"
      class="excursion-content flex flex-col flex-grow relative"
    >
      <div
        v-if="excursion.thumbnail"
        class="bg-cover bg-center h-48"
        :style="{ backgroundImage: `url(${excursion.thumbnail})` }"
      />
      <div
        v-else
        class="bg-gray-200 flex items-center justify-center h-48"
      >
        <span class="text-gray-500">No Image Available</span>
      </div>

      <div class="p-4 flex-grow">
        <h2 class="text-2xl font-bold text-gray-800 mb-2">
          {{ excursion.name }}
        </h2>

        <p class="text-gray-600 text-sm">
          <span v-if="!descriptionExpanded">
            {{ truncatedDescription + (truncatedDescription === excursion.description ? '' : '...') }}
          </span>
          <span v-else>
            {{ excursion.description }}
          </span>
          <button
            v-if="excursion.description.length > 100"
            class="text-purple-600 hover:underline text-xs ml-1"
            @click.stop="descriptionExpanded = !descriptionExpanded"
          >
            {{ descriptionExpanded ? 'Show less' : 'Read more' }}
          </button>
        </p>

        <div
          v-if="truncatedBookingDetails || isBookingDetailsLink"
          class="mt-3"
        >
          <h3 class="text-gray-700 font-semibold text-sm">
            Booking Details:
          </h3>
          <p class="text-gray-600 text-sm">
            <template v-if="isBookingDetailsLink">
              <a
                :href="excursion.booking_details"
                target="_blank"
                rel="noopener noreferrer"
                class="text-purple-600 hover:underline"
              >
                Click here to book
              </a>
            </template>
            <template v-else>
              <span v-if="!bookingDetailsExpanded">
                {{ truncatedBookingDetails + (truncatedBookingDetails === excursion.booking_details ? '' : '...') }}
              </span>
              <span v-else>
                {{ excursion.booking_details }}
              </span>
              <button
                v-if="excursion.booking_details && excursion.booking_details.length > 100"
                class="text-purple-600 hover:underline text-xs ml-1"
                @click.stop="bookingDetailsExpanded = !bookingDetailsExpanded"
              >
                {{ bookingDetailsExpanded ? 'Show less' : 'Read more' }}
              </button>
            </template>
          </p>
        </div>
      </div>

      <div class="absolute bottom-4 right-4">
        <div class="relative">
          <img
            v-if="excursion.suggested_by && excursion.suggested_by.avatar"
            :id="'avatar-tooltip-' + excursion.id"
            :src="excursion.suggested_by.avatar"
            :alt="excursion.suggested_by.name"
            class="w-10 h-10 rounded-full object-cover border-2 border-white"
            loading="lazy"
          >
        </div>
      </div>

      <div
        v-if="excursion.price"
        class="mt-3 p-4"
      >
        <h3 class="text-gray-700 font-semibold text-sm">
          Price:
        </h3>
        <p class="text-gray-600 text-sm">
          {{ excursion.price }}
        </p>
      </div>
    </div>

    <div
      v-else
      class="excursion-loading flex-grow flex flex-col"
    >
      <div class="bg-gray-300 h-48" />
      <div class="p-4 flex-grow">
        <div class="h-6 bg-gray-300 rounded w-3/4 mb-4" />
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 rounded w-full" />
          <div class="h-4 bg-gray-300 rounded w-5/6" />
        </div>
        <div class="mt-4">
          <div class="h-5 bg-gray-300 rounded w-1/3 mb-2" />
          <div class="space-y-2">
            <div class="h-4 bg-gray-300 rounded w-full" />
            <div class="h-4 bg-gray-300 rounded w-5/6" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="excursion"
      class="p-4 border-t border-gray-300 mt-auto"
    >
      <button
        class="w-full px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-purple-600 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
        @click.prevent="onView"
      >
        View
      </button>
    </div>
  </div>
</template>

<style scoped>
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>
