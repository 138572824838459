<script lang="ts" setup>
import { computed, defineProps, ref } from 'vue'
import { BoardGameResource, BoardGameLibraryEnum } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'

const props = defineProps<{
  boardGame: BoardGameResource
}>()

const descriptionExpanded = ref(false)
const mechanicsExpanded = ref(false)
const categoriesExpanded = ref(false)

const truncatedDescription = computed(() => {
  const description = props.boardGame.description
  return description.length > 100 ? description.slice(0, 100) : description
})

const truncatedMechanics = computed(() => {
  return props.boardGame.mechanics.length > 3 ? props.boardGame.mechanics.slice(0, 3) : props.boardGame.mechanics
})

const truncatedCategories = computed(() => {
  return props.boardGame.categories.length > 3 ? props.boardGame.categories.slice(0, 3) : props.boardGame.categories
})
</script>

<template>
  <div
    class="w-full bg-white rounded-lg overflow-hidden border border-gray-300"
    @click="handleCardClick"
  >
    <div
      v-if="props.boardGame.image"
      class="bg-cover bg-center h-48"
      :style="{ backgroundImage: `url(${props.boardGame.image})` }"
    />
    <div
      v-else
      class="bg-gray-200 flex items-center justify-center h-48"
    >
      <span class="text-gray-500">No Image Available</span>
    </div>

    <div class="p-3">
      <h2 class="text-xl font-bold text-gray-700">
        {{ props.boardGame.name }}
      </h2>
      <div class="flex items-center mt-1 mb-3 text-purple-600">
        <LavenderIcon class="h-4 w-4 fill-purple-600 mr-1" />
        <p class="text-xs mr-2">
          {{ props.boardGame.library === BoardGameLibraryEnum.His ? 'His' : 'Hers' }}
        </p>
        <div class="flex items-center text-xs mr-2">
          <i class="fas fa-users mr-1" />
          {{ props.boardGame.min_players }} - {{ props.boardGame.max_players }}
        </div>
        <div class="flex items-center text-xs">
          <i class="fas fa-hourglass-half mr-1" />
          {{ props.boardGame.min_playing_time }} - {{ props.boardGame.max_playing_time }} mins
        </div>
      </div>
      <p class="text-sm text-gray-600">
        <span v-if="!descriptionExpanded">{{ truncatedDescription }}...</span>
        <span v-else>{{ props.boardGame.description }}</span>
        <button
          v-if="props.boardGame.description.length > 100"
          class="text-purple-600 hover:underline text-xs"
          @click.stop="descriptionExpanded = !descriptionExpanded"
        >
          {{ descriptionExpanded ? 'Show less' : 'Read more' }}
        </button>
      </p>
    </div>

    <div class="p-3 border-t border-gray-300">
      <div>
        <span class="font-bold text-xs text-gray-600">Mechanics:</span>
        <div class="flex flex-wrap mt-1">
          <span
            v-for="mechanic in (mechanicsExpanded ? props.boardGame.mechanics : truncatedMechanics)"
            :key="mechanic"
            class="text-xs bg-purple-200 text-purple-700 rounded-full px-2 py-1 mr-1 mb-1"
          >{{ mechanic }}</span>
        </div>
        <button
          v-if="props.boardGame.mechanics.length > 3"
          class="text-purple-600 hover:underline text-xs"
          @click.stop="mechanicsExpanded = !mechanicsExpanded"
        >
          {{ mechanicsExpanded ? 'Show less' : 'Read more' }}
        </button>
      </div>
      <div class="mt-2">
        <span class="font-bold text-xs text-gray-600">Categories:</span>
        <div class="flex flex-wrap mt-1">
          <span
            v-for="category in (categoriesExpanded ? props.boardGame.categories : truncatedCategories)"
            :key="category"
            class="text-xs bg-purple-200 text-purple-700 rounded-full px-2 py-1 mr-1 mb-1"
          >{{ category }}</span>
        </div>
        <button
          v-if="props.boardGame.categories.length > 3"
          class="text-purple-600 hover:underline text-xs"
          @click.stop="categoriesExpanded = !categoriesExpanded"
        >
          {{ categoriesExpanded ? 'Show less' : 'Read more' }}
        </button>
      </div>
    </div>
  </div>
</template>
