<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue'
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { ExcursionResource, FeatureSettings } from '@/Types/generated'
import { usePage, Link } from '@inertiajs/vue3'

const emit = defineEmits(['close'])

const page = usePage()

const features = computed<FeatureSettings>(() => page.props.features)

const props = defineProps<{
  show: boolean
  excursion?: ExcursionResource
}>()

const onClose = () => {
  emit('close')
}

const isBookingDetailsLink = computed(() => {
  const details = props.excursion?.booking_details
  if (!details) return false
  try {
    const url = new URL(details)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch (_) {
    return false
  }
})
</script>

<template>
  <DialogModal
    :show="props.show"
    @close="onClose"
  >
    <template #title>
      <h2 class="text-lg font-medium text-gray-800 font-serif">
        {{ props.excursion?.name }}
      </h2>
    </template>

    <template #content>
      <div class="mt-4 space-y-4">
        <div
          v-if="props.excursion?.image"
          class="mb-4"
        >
          <img
            :src="props.excursion?.image"
            alt="Excursion Image"
            class="w-full h-64 object-cover rounded"
            loading="lazy"
          >
        </div>
        <div
          v-else
          class="bg-gray-200 flex items-center justify-center h-64 rounded mb-4"
        >
          <span class="text-gray-500">No Image Available</span>
        </div>

        <div>
          <h3 class="text-md font-semibold text-gray-700">
            Description:
          </h3>
          <p class="text-gray-600 text-sm">
            {{ props.excursion?.description }}
          </p>
        </div>

        <div v-if="props.excursion?.booking_details">
          <h3 class="text-md font-semibold text-gray-700">
            Booking Details:
          </h3>
          <p class="text-gray-600 text-sm">
            <template v-if="isBookingDetailsLink">
              <a
                :href="props.excursion.booking_details"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue-600 hover:underline"
              >
                {{ props.excursion.booking_details }}
              </a>
            </template>
            <template v-else>
              {{ props.excursion.booking_details }}
            </template>
          </p>
        </div>

        <div v-if="props.excursion?.price">
          <h3 class="text-md font-semibold text-gray-700">
            Price:
          </h3>
          <p class="text-gray-600 text-sm">
            {{ props.excursion.price }}
          </p>
        </div>

        <div
          v-if="props.excursion?.suggested_by && props.excursion.suggested_by.avatar"
          class="flex items-center space-x-2 mt-4"
        >
          <img
            :src="props.excursion.suggested_by.avatar"
            :alt="props.excursion.suggested_by.name"
            class="w-10 h-10 rounded-full object-cover border-2 border-gray-300"
            loading="lazy"
          >
          <span class="text-gray-700">Suggested by {{ props.excursion.suggested_by.name }}</span>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
      <Link
        v-if="features.activities_enabled"
        :href="route('activities.create', { excursion: props.excursion?.id })"
        class="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
      >
        Add To Calendar
      </Link>
    </template>
  </DialogModal>
</template>

<style scoped>
img {
  object-fit: cover;
}
</style>
