<script lang="ts" setup>
import { computed, ref } from 'vue'
import { usePage, router, WhenVisible } from '@inertiajs/vue3'
import Checkbox from '@/Components/UI/Inputs/CheckboxInput.vue'
import { TodoResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import TodoModal from '@/Components/Todos/TodoModal.vue'

const page = usePage()
const todos = computed<TodoResource[]|undefined>(() => page.props.todos)

const isLoading = ref(false)
const selectedResource = ref<TodoResource | undefined>(undefined)
const todoModalOpen = ref<boolean>(false)

const toggleTodo = (todo: TodoResource) => {
  if (todo.is_locked) {
    return
  }

  isLoading.value = true

  router.put(route('todos.toggle', { todo: todo.id }), {
    is_completed: !todo.is_completed
  }, {
    preserveState: true,
    preserveScroll: true,
    only: ['todos'],
    onFinish: () => {
      isLoading.value = false
    }
  })
}

const openModal = (todo: TodoResource) => {
  selectedResource.value = todo
  todoModalOpen.value = true
}
</script>

<template>
  <TodoModal
    v-if="selectedResource"
    :show="todoModalOpen"
    :todo="selectedResource"
    @close="todoModalOpen = false"
  />
  <div
    id="checklist"
    class="w-full mt-8 text-center scroll-mt-20"
  >
    <div>
      <div class="flex items-center justify-center flex-col text-center">
        <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
          Checklist
        </h2>
        <div class="flex items-center flex-row justify-center">
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
          <div class="flex items-center">
            <LavenderIcon />
          </div>
          <div class="text-gray-400 px-2">
            &#8212;&#8212;
          </div>
        </div>
      </div>
    </div>

    <WhenVisible
      data="todos"
      :buffer="500"
    >
      <div
        v-if="todos.length === 0"
        class="text-gray-600 mt-4"
      >
        No items on your checklist!
      </div>
      <div
        v-for="todo in todos"
        :key="todo.id"
        class="my-2 flex items-center justify-center"
      >
        <Checkbox
          :id="'todo-' + todo.id"
          type="checkbox"
          class="mr-4 inline-block"
          :checked="todo.is_completed ?? false"
          :disabled="todo.is_locked || isLoading"
          :class="{ 'cursor-not-allowed opacity-50': todo.is_locked || isLoading }"
          @click="toggleTodo(todo)"
        />
        <label
          :class="[
            todo.is_completed ? 'line-through text-gray-500' : 'text-gray-900',
            todo.is_locked ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
          ]"
          class="text-lg leading-6 font-medium inline-block"
          :for="'todo-' + todo.id"
        >
          {{ todo.name }}
        </label>
        <a
          v-if="todo.details"
          href="javascript:void(0)"
          class="ml-4 text-purple-600 hover:text-purple-800 font-semibold transition duration-200"
          @click.prevent="openModal(todo)"
        >
          Details
        </a>
      </div>
    </WhenVisible>
  </div>
</template>
