<script lang="ts" setup>
import { computed, defineProps, ref } from 'vue'
import { ActivityResource } from '@/Types/generated'
import { Link } from '@inertiajs/vue3'
import ActivityGuests from '@/Components/Activities/ActivityGuests.vue'

const props = defineProps<{
  activity?: ActivityResource;
}>()

const descriptionExpanded = ref(false)
const bookingDetailsExpanded = ref(false)

const truncatedDescription = computed(() => {
  const description = props.activity?.description ?? ''
  return description.length > 100 ? description.slice(0, 100) : description
})

const truncatedBookingDetails = computed(() => {
  const details = props.activity?.booking_details ?? ''
  return details.length > 100 ? details.slice(0, 100) : details
})

const toggleDescription = () => {
  descriptionExpanded.value = !descriptionExpanded.value
}

const toggleBookingDetails = () => {
  bookingDetailsExpanded.value = !bookingDetailsExpanded.value
}

const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return 'N/A'
  const date = new Date(dateStr)
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const formatTime = (timeStr: string | null): string => {
  if (!timeStr) return 'N/A'

  const date = new Date(timeStr)

  if (isNaN(date.getTime())) {
    return 'Invalid Time'
  }

  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}

const isPast = computed(() => {
  if (!props.activity?.starts_at) return false
  const startDate = new Date(props.activity.starts_at)
  const now = new Date()
  return startDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)
})
</script>

<template>
  <div
    :class="[
      'w-full bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300 transition-transform transform hover:scale-105 cursor-pointer mb-4',
      isPast ? 'opacity-50 grayscale' : ''
    ]"
    :aria-label="`View details for ${activity?.name}`"
  >
    <Link
      v-if="activity"
      prefetch="click"
      class="activity-content flex flex-col md:flex-row"
      :href="route('activities.show', activity.id)"
    >
      <div
        v-if="activity.image"
        class="md:w-1/3 bg-cover bg-center h-48 md:h-auto"
        :style="{ backgroundImage: `url(${activity.image})` }"
      />
      <div
        v-else
        class="md:w-1/3 bg-gray-200 flex items-center justify-center h-48 md:h-auto"
      >
        <span class="text-gray-500">No Image Available</span>
      </div>

      <div class="p-4 md:w-2/3 flex flex-col justify-between">
        <div>
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            {{ activity.name }}
          </h2>

          <div class="flex items-center text-gray-600 text-sm mb-3">
            <div class="flex items-center mr-4">
              <i class="fas fa-calendar-alt mr-1 text-purple-600" />
              <span>
                {{ formatDate(activity.starts_at) }}
              </span>
            </div>
            <div class="flex items-center mr-4">
              <i class="fas fa-clock mr-1 text-purple-600" />
              <span>
                {{ formatTime(activity.starts_at) }}
              </span>
            </div>
            <div
              v-if="activity.price"
              class="flex items-center mr-4"
            >
              <i class="fas fa-tag mr-1 text-purple-600" />
              <span>
                {{ activity.price }}
              </span>
            </div>
          </div>

          <ActivityGuests :guests="activity.guests" />

          <p class="text-gray-600 text-sm mb-3 mt-3">
            <span v-if="!descriptionExpanded">{{ truncatedDescription + (truncatedDescription.value === activity.description ? '' : '...') }}</span>
            <span v-else>{{ activity.description }}</span>
            <button
              v-if="activity.description.length > 100"
              class="text-purple-600 hover:underline text-xs ml-1"
              aria-label="Toggle description"
              @click.stop="toggleDescription"
            >
              {{ descriptionExpanded ? 'Show less' : 'Read more' }}
            </button>
          </p>

          <div
            v-if="activity.booking_details"
            class="mt-3"
          >
            <h3 class="text-gray-700 font-semibold text-sm">
              Booking Details:
            </h3>
            <p class="text-gray-600 text-sm">
              <span v-if="!bookingDetailsExpanded">
                {{ truncatedBookingDetails + (truncatedBookingDetails.value === activity.booking_details ? '' : '...') }}
              </span>
              <span v-else>
                {{ activity.booking_details }}
              </span>
              <button
                v-if="activity.booking_details && activity.booking_details.length > 100"
                class="text-purple-600 hover:underline text-xs ml-1"
                aria-label="Toggle booking details"
                @click.stop="toggleBookingDetails"
              >
                {{ bookingDetailsExpanded ? 'Show less' : 'Read more' }}
              </button>
            </p>
          </div>

          <div
            v-if="activity.price"
            class="mt-3"
          >
            <h3 class="text-gray-700 font-semibold text-sm">
              Price:
            </h3>
            <p class="text-gray-600 text-sm">
              {{ activity.price }}
            </p>
          </div>
        </div>

        <div class="pt-4">
          <Link
            class="w-full px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
            :href="route('activities.show', activity.id)"
          >
            View
          </Link>
        </div>
      </div>
    </Link>

    <div
      v-else
      class="activity-loading flex flex-col md:flex-row"
    >
      <div class="md:w-1/3 bg-gray-300 h-48 md:h-auto animate-pulse" />
      <div class="p-4 md:w-2/3">
        <div class="h-6 bg-gray-300 rounded w-3/4 mb-4" />
        <div class="space-y-2">
          <div class="h-4 bg-gray-300 rounded w-full" />
          <div class="h-4 bg-gray-300 rounded w-5/6" />
        </div>
        <div class="mt-4">
          <div class="h-5 bg-gray-300 rounded w-1/3 mb-2" />
          <div class="space-y-2">
            <div class="h-4 bg-gray-300 rounded w-full" />
            <div class="h-4 bg-gray-300 rounded w-5/6" />
          </div>
        </div>
        <div class="mt-4">
          <div class="h-10 bg-gray-300 rounded w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.activity-content {
  transition: all 0.3s ease-in-out;
}
</style>
