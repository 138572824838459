<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch, inject } from 'vue'
import { Commentable, CommentReactionSummaryResource, CommentResource, ConfigDTO, UserResource } from '@/Types/generated'
import CommentsArea from '@/Components/Comments/CommentsArea.vue'
import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import { CommentResponse } from '@/Types/comments'
import { makeConfirmationModal } from '@/Helpers/ConfirmationModalHelper'
import { useToast } from 'vue-toastification'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

defineOptions({
  name: 'CommentComponent'
})

const props = defineProps<{
  comment?: CommentResource;
  isNested?: boolean;
}>()

const page = usePage<{ config: ConfigDTO, user?: UserResource }>()
const config = computed((): ConfigDTO => page.props.config)
const comment = ref<CommentResource | undefined>(props.comment)
const hoveredReaction = ref<CommentReactionSummaryResource | null>(null)

const user = computed<UserResource | undefined>(() => page.props.user ?? undefined)

watch(
  () => props.comment,
  (newComment) => {
    comment.value = newComment
  }
)

const isNested = computed(() => props.isNested || false)

const isLoading = ref(false)
const errorMessage = ref<string | null>(null)
const showReactionDropdown = ref(false)

const deleteComment = () => {
  if (!comment.value) return

  makeConfirmationModal('comment')
    .then(() => {
      axios.delete<CommentResponse>(route('api.comments.destroy', { comment: comment.value.id }))
        .then((response) => {
          comment.value = response.data.comment
          useToast().success('Comment deleted.')
        })
        .catch((error: unknown) => {
          console.error('Error deleting comment:', error)
          errorMessage.value = 'Failed to delete comment. Please try again.'
        })
    })
    .catch(() => {
      console.log('User cancelled the action')
    })
}

const handleReact = (reaction: string) => {
  if (!comment.value) return
  isLoading.value = true
  errorMessage.value = null

  axios.post<CommentResponse>(
    route('api.reactions.store', { comment: comment.value.id }), { reaction })
    .then((response) => {
      comment.value = response.data.comment
    })
    .catch((error: unknown) => {
      console.error('Error reacting to comment:', error)
      errorMessage.value = 'Failed to react to comment. Please try again.'
    })
    .finally(() => {
      isLoading.value = false
    })
}

const deleteReaction = (reaction: string) => {
  if (!comment.value) return
  isLoading.value = true
  errorMessage.value = null

  axios
    .delete<CommentResponse>(
      route('api.reactions.destroy', { comment: comment.value.id }),
      { data: { reaction } }
    )
    .then((response) => {
      comment.value = response.data.comment
    })
    .catch((error: unknown) => {
      console.error('Error deleting reaction:', error)
      errorMessage.value = 'Failed to delete reaction. Please try again.'
    })
    .finally(() => {
      isLoading.value = false
    })
}

const refreshComment = () => {
  if (!comment.value) return
  isLoading.value = true
  errorMessage.value = null

  axios.get<CommentResource>(route('api.comments.show', { comment: comment.value.id }))
    .then((response) => {
      comment.value = response.data

      if (comment.value.is_deleted) {
        /* eslint-disable */
        window.Echo.leaveChannel(`Comment.${comment.value.id}`)
        /* eslint-enable */
      }
    })
    .catch((error: unknown) => {
      console.error('Error refreshing comment:', error)
      errorMessage.value = 'Failed to refresh comment. Please try again.'
    })
    .finally(() => {
      isLoading.value = false
    })
}

function toggleReactionDropdown () {
  showReactionDropdown.value = !showReactionDropdown.value
}

const getReactionButtonClasses = (reacted: boolean) => [
  'flex items-center space-x-1 px-3 py-1 rounded-full transition-colors duration-200',
  reacted ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-700',
  'hover:bg-purple-400 hover:text-white'
]

const commentContainerClasses = computed(() => [
  'mb-6 p-4 border border-gray-200 rounded-lg shadow-sm bg-white',
  isNested.value ? 'ml-8' : ''
])

const formattedDate = computed(() => {
  return comment.value ? new Date(comment.value.created_at).toLocaleDateString() : ''
})

const showTooltip = (reaction: CommentReactionSummaryResource) => {
  hoveredReaction.value = reaction
}

const hideTooltip = () => {
  hoveredReaction.value = null
}

onMounted(() => {
  if (!comment.value || comment.value.is_deleted) return

  /* eslint-disable */

  window.Echo.private(`Comment.${comment.value.id}`)
    .listen('.CommentUpdated', () => {
      refreshComment()
    })
    .listen('.CommentDeleted', () => {
      refreshComment()
    })

  onUnmounted(() => {
    window.Echo.leaveChannel(`Comment.${comment.value.id}`)
  })
  /* eslint-enable */
})
</script>

<template>
  <div
    v-if="comment"
    :class="commentContainerClasses"
  >
    <div class="flex items-center space-x-4">
      <img
        :src="comment.user?.guest?.avatar"
        :alt="comment.user?.name"
        class="w-10 h-10 rounded-full object-cover"
        loading="lazy"
      >
      <div>
        <span class="font-semibold text-gray-800">{{ comment.user?.name }}</span>
        <span class="text-gray-500 text-xs ml-2">{{ formattedDate }}</span>
      </div>
    </div>

    <div class="mt-2">
      <p
        class="text-gray-700 whitespace-pre-wrap"
        v-html="comment.text"
      />
    </div>

    <div
      v-if="!comment.is_deleted || (user && comment.reactions?.length > 0)"
      class="flex items-center space-x-4 mb-4"
    >
      <div class="flex space-x-2">
        <div
          v-for="reaction in comment.reactions"
          :key="reaction.reaction"
          class="relative"
        >
          <button
            :class="getReactionButtonClasses(reaction.commentator_reacted)"
            :disabled="isLoading || !user"
            :aria-label="'React with ' + reaction.reaction"
            @click="reaction.commentator_reacted ? deleteReaction(reaction.reaction) : handleReact(reaction.reaction)"
            @mouseenter="showTooltip(reaction)"
            @mouseleave="hideTooltip"
          >
            <span class="text-lg">{{ reaction.reaction }}</span>
            <span class="ml-1 text-sm">{{ reaction.count }}</span>
          </button>

          <transition name="fade">
            <div
              v-if="hoveredReaction === reaction"
              class="absolute z-20 bg-white border border-gray-200 rounded shadow-lg p-2 mt-2 left-1/2 transform -translate-x-1/2 w-48"
            >
              <ul class="text-sm">
                <li
                  v-for="user in reaction.reacted_by"
                  :key="user.id"
                  class="flex items-center space-x-2 py-1 hover:bg-gray-100 rounded"
                >
                  <img
                    :src="user.avatar"
                    :alt="user.name"
                    class="w-6 h-6 rounded-full object-cover"
                  >

                  <span>{{ user.name }}</span>
                </li>
                <li
                  v-if="reaction.reacted_by.length === 0"
                  class="text-gray-500"
                >
                  No reactions yet.
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>

      <div
        v-if="user"
        class="relative"
      >
        <button
          class="flex items-center justify-center w-10 h-10 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors duration-200"
          :disabled="isLoading"
          aria-label="Add Reaction"
          @click="toggleReactionDropdown"
        >
          <i class="fas fa-smile text-lg" />
        </button>
        <transition name="fade">
          <div
            v-if="showReactionDropdown"
            class="absolute mt-2 bg-white border border-gray-200 rounded shadow-md z-10 p-2 w-max"
          >
            <div class="grid grid-cols-5 gap-2">
              <button
                v-for="reaction in config.comments.allowed_reactions"
                :key="reaction"
                class="w-10 h-10 hover:bg-gray-100 rounded cursor-pointer text-2xl flex items-center justify-center"
                @click="handleReact(reaction); showReactionDropdown = false"
              >
                {{ reaction }}
              </button>
            </div>
          </div>
        </transition>
      </div>

      <button
        v-if="comment.can?.delete"
        class="text-red-500 hover:text-red-700 transition-colors duration-200"
        :disabled="isLoading"
        aria-label="Delete Comment"
        @click="deleteComment"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </div>

    <div
      v-if="errorMessage"
      class="text-red-500 text-sm mt-2"
    >
      {{ errorMessage }}
    </div>

    <CommentsArea
      :commentable-type="Commentable.Comment"
      :commentable-id="comment.id"
      :comment-count="comment.comments_count ?? 0"
      :is-nested="true"
      :readonly="comment.is_deleted"
      :no-sockets="comment.is_deleted"
    />
  </div>
</template>

<style scoped>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Reaction Buttons */
button {
  outline: none;
}

button:focus {
  box-shadow: 0 0 0 2px rgba(129, 140, 248, 0.5);
}

.ml-8 {
  margin-left: 2rem;
}
</style>
