<script setup lang="ts">
import { computed } from 'vue'
import ApplicationLogo from '@/Components/UI/ApplicationLogo.vue'
import { Link, Head } from '@inertiajs/vue3'

interface Props {
  status: 503 | 500 | 404 | 403
}

const props = defineProps<Props>()

const title = computed<string>(() => {
  const titles: Record<Props['status'], string> = {
    503: '503: Service Unavailable',
    500: '500: Server Error',
    404: '404: Page Not Found',
    403: '403: Forbidden'
  }
  return titles[props.status]
})

const description = computed<string>(() => {
  const descriptions: Record<Props['status'], string> = {
    503: "We're making some changes. Please check back soon.",
    500: 'Whoops, something went wrong on our servers.',
    404: 'Sorry, the page you are looking for could not be found.',
    403: 'Sorry, you are forbidden from accessing this page.'
  }
  return descriptions[props.status]
})

const canGoHome = computed<boolean>(() => [500, 403, 404].includes(props.status))
</script>

<template>
  <div
    :style="{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(' + $asset('images/error-bg.jpg') + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"
    class="min-h-screen flex flex-col justify-center items-center pt-6 sm:pt-0 px-4 text-center"
  >
    <Head title="Hargrave Wedding" />

    <div class="mb-6">
      <Link :href="canGoHome ? route('home') : ''">
        <ApplicationLogo />
      </Link>
    </div>

    <h1 class="text-4xl sm:text-6xl font-bold text-white drop-shadow-2xl">
      {{ title }}
    </h1>
    <p class="text-xl sm:text-3xl mt-4 text-white drop-shadow-lg">
      {{ description }}
    </p>
    <div
      v-if="canGoHome"
      class="mt-6"
    >
      <Link
        :href="route('home')"
        class="inline-flex items-center px-6 py-3 sm:px-4 sm:py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs sm:text-base text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
      >
        Go back to Homepage
      </Link>
    </div>
  </div>
</template>
