import './bootstrap'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { createApp, h } from 'vue'
import { createInertiaApp, AppType, PageResolver } from '@inertiajs/vue3'
import { ZiggyVue } from 'ziggy-js'
import { Ziggy } from './ziggy.js'

import '@fortawesome/fontawesome-free/css/all.css'
import { asset, assetBg } from '../../Modules/AssetUploader/Resources/Asset'

import * as Sentry from '@sentry/vue'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueObserveVisibility from 'vue3-observe-visibility'
import VueTippy from 'vue-tippy'

import './echo'

/* eslint-disable */
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC
})

const resolvePage: PageResolver = (name: string): AppType => {
  const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
  const page = pages[`./Pages/${name}.vue`]

  if (!page || !page.default) {
    throw new Error(`Page not found: ${name}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return page.default
}

createInertiaApp({
  resolve: resolvePage,
  setup ({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    app.config.globalProperties.$asset = asset
    app.config.globalProperties.$assetBg = assetBg

    app
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(Toast)
      .use(VueObserveVisibility)
      .use(VueTippy, {
        directive: 'tippy',
        component: 'tippy'
      })
      .mount(el)
  },
  progress: {
    color: '#581c87'
  }
}).catch((error: unknown) => {
  console.error('Failed to create Inertia app', error)
})
/* eslint-enable */
