import { watch } from 'vue'
import { useToast } from 'vue-toastification'

interface FlashMessage {
    message?: string;
}

interface PageProps {
    flash?: FlashMessage;
}

export function useToastMessage (props: PageProps) {
  const toast = useToast()

  const showFlashMessage = (flash: FlashMessage | undefined) => {
    if (flash?.message) {
      toast.success(flash.message)
    }
  }

  watch(() => props.flash, (newFlash) => {
    showFlashMessage(newFlash)
  }, { immediate: true })
}
