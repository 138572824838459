<script lang="ts" setup>
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { useConfirmationModal } from '@/Composables/UseConfirmationModal'

const { showModal, resource, closeModal, confirmModal } = useConfirmationModal()
</script>

<template>
  <DialogModal :show="showModal">
    <template #title>
      <div class="text-center">
        <svg
          class="w-16 h-16 mx-auto text-yellow-400 mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <h2 class="text-xl font-semibold text-gray-800">
          Are you sure?
        </h2>
      </div>
    </template>
    <template #content>
      <p class="text-gray-600 text-center mt-2 mb-6">
        Are you sure you want to delete this {{ resource ?? 'item' }}?<br>
        This action cannot be undone.
      </p>
    </template>
    <template #footer>
      <div class="flex justify-end space-x-3">
        <button
          type="button"
          class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          @click.prevent="confirmModal"
        >
          Delete
        </button>
      </div>
    </template>
  </DialogModal>
</template>
