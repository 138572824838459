<script setup lang="ts">
import Multiselect from 'vue-multiselect'
import { inject, ref, computed } from 'vue'
import { SelectResource } from '@/Types/generated'
import axios from 'axios'
import { route as routeFn } from 'ziggy-js'
import { debounce } from 'lodash'

const route = inject<typeof routeFn>('route')
const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps<{
  modelValue: SelectResource[],
  routeName: string,
  multiple: boolean
}>()

const isLoading = ref<boolean>(false)
const options = ref<SelectResource[]>([])

/* eslint-disable */
const search = debounce(async (searchTerm: string) => {
  isLoading.value = true

  try {
    const response = await axios.get(route(props.routeName, { search: searchTerm }))
    options.value = response.data as SelectResource[]
  } catch (error: unknown) {
    // Handle error appropriately if needed
  } finally {
    isLoading.value = false
  }
}, 300)
/* eslint-enable */

const model = computed<SelectResource[]>({
  get: () => props.modelValue,
  set: (newValue: SelectResource[]) => {
    const nonRemovableItems = props.modelValue.filter(item => !item.is_removable)
    const removableItems = newValue.filter(item => item.is_removable)
    const combined = [...removableItems, ...nonRemovableItems]
    const uniqueCombined = Array.from(new Map(combined.map(item => [item.id, item])).values())

    emit('update:modelValue', uniqueCombined)
    emit('change', uniqueCombined)
  }
})
</script>

<template>
  <multiselect
    v-model="model"
    :options="options"
    :internal-search="false"
    :limit="3"
    :multiple="props.multiple"
    label="name"
    track-by="id"
    :loading="isLoading"
    :clear-on-select="false"
    :close-on-select="false"
    @search-change="search"
  >
    <template #tag="{ option, remove }">
      <span
        :key="option.id"
        class="multiselect__tag"
      >
        <span>{{ option.name }}</span>
        <i
          v-if="option.is_removable"
          tabindex="1"
          class="multiselect__tag-icon"
          @keypress.enter.prevent="remove(option)"
          @mousedown.prevent="remove(option)"
        />
      </span>
    </template>
  </multiselect>
</template>
