<script lang="ts" setup>
import { defineProps, defineEmits, watch, computed, inject } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { EventResource, UserResource, EventAttendanceEnum } from '@/Types/generated'
import { format } from 'date-fns'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import TextArea from '@/Components/UI/Inputs/TextArea.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const props = defineProps<{ show: boolean; event?: EventResource }>()

const page = usePage()

const user = computed<UserResource | undefined>(() => page.props.user ?? undefined)

const emit = defineEmits(['close'])

const form = useForm<{ attendance: string; dietary_restrictions: string }>({
  attendance: props.event?.attendance ?? '',
  dietary_restrictions: user.value?.guest?.dietary_restrictions ?? ''
})

watch(() => props.event, () => {
  form.reset()
  form.attendance = props.event?.attendance ?? ''
})

watch(() => user, () => {
  form.dietary_restrictions = user.value?.guest?.dietary_restrictions ?? ''
})

const onSubmit = () => {
  form.post(route('events.attendance.store', { event: props.event?.id }), {
    only: ['events', 'todos', 'flash'],
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => {
      onClose()
    }
  })
}

const onClose = () => {
  emit('close')
}

const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'MMMM dd, yyyy h:mm a')
}
</script>

<template>
  <DialogModal :show="props.show">
    <template #title>
      <h2 class="text-lg font-medium text-gray-800 font-serif">
        RSVP - {{ props.event?.name }}
      </h2>
    </template>
    <template #content>
      <div class="mt-2 space-y-4">
        <div>
          <p
            v-if="props.event?.starts_at"
            class="text-sm text-gray-500"
          >
            {{ formatDate(props.event?.starts_at) }}
          </p>
        </div>
        <div v-html="props.event?.details" />
        <div>
          <label
            for="attendance"
            class="block text-sm font-medium text-gray-700"
          >
            Will you be attending?
          </label>
          <select
            id="attendance"
            v-model="form.attendance"
            name="attendance"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
          >
            <option value="">
              Select an option
            </option>
            <option :value="EventAttendanceEnum.Attending">
              Yes, I'll be there
            </option>
            <option :value="EventAttendanceEnum.NotAttending">
              Sorry, I can't make it
            </option>
          </select>
          <div
            v-if="form.errors.attendance"
            class="text-red-500 text-sm mt-1"
          >
            {{ form.errors.attendance }}
          </div>
        </div>

        <div
          v-if="form.attendance === EventAttendanceEnum.Attending && props.event?.has_food"
          class="mt-4"
        >
          <InputLabel
            for="dietary_restrictions"
            value="Dietary Restrictions"
          />

          <TextArea
            id="dietary_restrictions"
            v-model="form.dietary_restrictions"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.dietary_restrictions"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
      <button
        type="button"
        class="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onSubmit"
      >
        Submit RSVP
      </button>
    </template>
  </DialogModal>
</template>
