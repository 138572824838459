<script lang="ts" setup>
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import TextArea from '@/Components/UI/Inputs/TextArea.vue'
import { Head, Link, useForm, usePage } from '@inertiajs/vue3'
import { computed, inject, ref } from 'vue'
import Checkbox from '@/Components/UI/Inputs/CheckboxInput.vue'
import { useToastMessage } from '@/Composables/UseToasts'
import { MediaLibraryAttachment } from 'media-library-pro-vue3-attachment'
import { NotificationTypeResource, UserResource } from '@/Types/generated'
import { MediaAttributes } from 'media-library-pro-vue3'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface Form {
  email?: string;
  change_password: boolean;
  password: string;
  password_confirmation: string;
  has_newsletter?: boolean;
  enabled_notifications: string[];
  guest: {
    name: string;
    dietary_restrictions: string;
    avatar?: MediaAttributes
  };
}

const props = defineProps<{
  background: string;
  flash: { message: string };
  back?: string;
}>()

const page = usePage()

const user = computed<UserResource>(() => page.props.user)
const notifications = computed<NotificationTypeResource[]>(() => page.props.notifications)

const hasUploadsInProgress = ref<boolean>(false)
const isReadyToSubmit = ref<boolean>(false)

const form = useForm<Form>({
  email: user.value.email,
  change_password: false,
  password: '',
  password_confirmation: '',
  has_newsletter: user.value.has_newsletter,
  enabled_notifications: user.value.enabled_notifications ?? [],
  guest: {
    name: user.value.guest?.name ?? '',
    dietary_restrictions: user.value.guest?.dietary_restrictions ?? ''
  }
})

const submit = (): void => {
  form.put(route('account.update'), {
    preserveState: true,
    preserveScroll: true,
    onFinish: () => form.reset()
  })
}

useToastMessage(props)
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="Manage Account | Hargrave Wedding" />

    <div>
      <form @submit.prevent="submit">
        <div>
          <InputLabel
            for="name"
            value="Name"
          />

          <TextInput
            id="name"
            v-model="form.guest.name"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.guest?.name"
          />
        </div>

        <div class="mt-4">
          <InputLabel
            for="email"
            value="Email"
          />

          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.email"
          />
        </div>

        <div
          v-if="form.change_password"
          class="mt-4"
        >
          <InputLabel
            for="password"
            value="Password"
          />

          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.password"
          />
        </div>

        <div
          v-if="form.change_password"
          class="mt-4"
        >
          <InputLabel
            for="password_confirmation"
            value="Confirm Password"
          />

          <TextInput
            id="password_confirmation"
            v-model="form.password_confirmation"
            type="password"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.password_confirmation"
          />
        </div>

        <div class="block mt-4">
          <label class="flex items-center">
            <Checkbox
              v-model:checked="form.change_password"
              name="remember"
            />
            <span class="ms-2 text-sm text-gray-600">Change Password</span>
          </label>
        </div>

        <div class="mt-4">
          <InputLabel
            for="dietary_restrictions"
            value="Dietary Restrictions"
          />

          <TextArea
            id="email"
            v-model="form.guest.dietary_restrictions"
            class="mt-1 block w-full"
            required
            autofocus
          />

          <InputError
            class="mt-2"
            :message="form.errors.guest?.dietary_restrictions"
          />
        </div>

        <div class="mt-4">
          <InputLabel
            for="avatar"
            value="Avatar"
          />

          <MediaLibraryAttachment
            v-model="form.guest.avatar"
            name="guest.avatar"
            :validation-errors="form.errors"
            :validation-rules="{ accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/avif', 'image/webp'] }"
            :initial-value="user.guest?.avatar_media"
            route-prefix="media"
            @change="form.guest.avatar = $event"
            @is-ready-to-submit-change="isReadyToSubmit = $event"
            @has-uploads-in-progress-change="hasUploadsInProgress = $event"
          />

          <InputError
            class="mt-2"
            :message="form.errors.guest?.avatar"
          />
        </div>

        <div class="block mt-4">
          <InputLabel
            for="notifications"
            value="Notifications"
            class="mb-2"
          />

          <label
            v-for="notification in notifications"
            :key="notification.notification"
            class="flex items-center"
          >
            <Checkbox
              v-model:checked="form.enabled_notifications"
              :value="notification.notification"
            />
            <span class="ms-2 text-sm text-gray-600">{{ notification.label }}</span>
          </label>

          <label class="flex items-center">
            <Checkbox
              v-model:checked="form.has_newsletter"
              name="remember"
            />
            <span class="ms-2 text-sm text-gray-600">Newsletter</span>
          </label>
        </div>

        <div />

        <div class="flex items-center justify-end mt-4">
          <Link
            :href="back ?? route('home')"
            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back
          </Link>

          <PrimaryButton
            class="ms-4"
            :class="{ 'opacity-25': form.processing || hasUploadsInProgress || !isReadyToSubmit }"
            :disabled="form.processing || hasUploadsInProgress || !isReadyToSubmit"
          >
            Save
          </PrimaryButton>
        </div>
      </form>
    </div>
  </GuestLayout>
</template>
