<script setup lang="ts">
</script>

<template>
  <div
    class="w-full h-96 lg:h-[36rem] bg-cover bg-top relative"
    :style="{
      backgroundImage: 'url(' + $asset('images/banner.jpg') + ')',
      backgroundPosition: 'center right',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }"
  >
    <div class="absolute top-3/4 w-full">
      <p class="text-center text-white text-4xl sm:text-7xl font-serif font-bold">
        Hannah & Andrew
      </p>
      <p class="text-center text-purple-200 text-xl font-mono">
        19th November 2024 - 28th November 2024
      </p>
    </div>
  </div>
</template>
