<script lang="ts" setup>
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import { Head, useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'
import { inject } from 'vue'

const route = inject<typeof routeFn>('route')

interface Form {
  email: string;
}

const props = defineProps<{status: string, background: string|null|undefined}>()

const form = useForm<Form>({
  email: ''
})

const submit = (): void => {
  form.post(route('password.email'))
}
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="Forgot Password | Hargrave Wedding" />

    <div class="mb-4 text-sm text-gray-600">
      Forgot your password? No problem. Just let us know your email address and we will email you a password reset
      link that will allow you to choose a new one.
    </div>

    <div
      v-if="status"
      class="mb-4 font-medium text-sm text-green-600"
    >
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div>
        <InputLabel
          for="email"
          value="Email"
        />

        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="username"
        />

        <InputError
          class="mt-2"
          :message="form.errors.email"
        />
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Email Password Reset Link
        </PrimaryButton>
      </div>
    </form>
  </GuestLayout>
</template>
