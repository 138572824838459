<script lang="ts" setup>
/* eslint-disable */
import { EditorContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import axios from 'axios'
import tippy, { Instance } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { route as routeFn } from 'ziggy-js'
import { SelectResource } from '@/Types/generated'
import {watch, defineProps, defineEmits, inject} from 'vue'

const route = inject<typeof routeFn>('route')

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

interface SuggestionProps {
  items: { id: number; label: string }[];
  selectedIndex: number;
  clientRect: () => DOMRect | undefined;
  command: (mention: { id: string; label: string }) => void;
}

const fetchMentions = async (query: string): Promise<SelectResource[]> => {
  try {
    const response = await axios.get(route('api.guests.index', { search: query }))
    return response.data as SelectResource[]
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching mentions:', error.message)
    } else {
      console.error('Unknown error:', error)
    }
    return []
  }
}

let tippyInstance: Instance | null = null

const editor = useEditor({
  extensions: [
    StarterKit,
    Mention.configure({
      HTMLAttributes: {
        class: 'bg-purple-200 text-purple-700 px-2 py-1 rounded',
      },
      suggestion: {
        char: '@',
        items: async ({ query }: { query: string }) => {
          const mentions = await fetchMentions(query);
          return mentions.map((item: SelectResource) => ({
            id: item.id,
            label: item.name,
          }));
        },
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: 'mention',
                attrs: {
                  id: props.id,
                  label: props.label,
                },
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },
        render: () => {
          let suggestionContainer: HTMLElement | null = null;
          let capturedProps: SuggestionProps;

          function updateSuggestionList(props: SuggestionProps) {
            suggestionContainer!.innerHTML = `
              <div class="bg-white shadow-md border border-gray-300 rounded-md">
                <ul class="divide-y divide-gray-200">
                  ${props.items
              .map(
                (item, index) => `
                    <li class="p-2 text-black cursor-pointer ${
                  props.selectedIndex === index ? 'bg-gray-200' : ''
                }" data-id="${String(item.id)}" data-label="${item.label}">
                      ${item.label}
                    </li>`
              )
              .join('')}
                </ul>
              </div>
            `;
          }

          function onSuggestionClick(event: Event) {
            const target = event.target as HTMLElement;
            const mentionId = target.getAttribute('data-id');
            const mentionLabel = target.getAttribute('data-label');

            if (mentionId && mentionLabel) {
              const mentionProps = { id: mentionId, label: mentionLabel };
              capturedProps.command(mentionProps);
              tippyInstance?.hide();
            }
          }

          return {
            onStart: (props: SuggestionProps) => {
              capturedProps = props;
              suggestionContainer = document.createElement('div');
              updateSuggestionList(props);

              const rect = props.clientRect();

              if (tippyInstance) {
                tippyInstance.destroy();
              }

              tippyInstance = tippy(document.body, {
                content: suggestionContainer!,
                trigger: 'manual',
                placement: 'bottom-start',
                interactive: true,
                appendTo: () => document.body,
                getReferenceClientRect: () => rect,
                theme: 'light',
              });

              suggestionContainer.addEventListener('click', onSuggestionClick);

              tippyInstance.show();
            },
            onUpdate: (props: SuggestionProps) => {
              capturedProps = props;
              if (tippyInstance && suggestionContainer) {
                updateSuggestionList(props);

                const rect = props.clientRect();
                if (rect) {
                  tippyInstance.setProps({
                    getReferenceClientRect: () => rect,
                  });
                }
              }
            },
            onExit: () => {
              if (tippyInstance) {
                tippyInstance.hide();
                tippyInstance.destroy();
                tippyInstance = null;
              }
              if (suggestionContainer) {
                suggestionContainer.removeEventListener('click', onSuggestionClick);
                suggestionContainer = null;
              }
            },
          };
        },
      },
    }),
  ],
  content: props.modelValue,
  onUpdate: ({ editor: updatedEditor }) => {
    emit('update:modelValue', updatedEditor.getHTML());
  },
});


watch(
  () => props.modelValue,
  (newValue) => {
    if (editor.value?.getHTML() !== newValue) {
      editor.value?.commands.setContent(newValue)
    }
  }
)
/* eslint-enable */
</script>

<template>
  <div
    class="w-full p-2 border border-gray-300 rounded-lg focus-within:border-purple-600 focus-within:ring-1 focus-within:ring-purple-500"
  >
    <EditorContent
      :editor="editor"
      class="editor-content"
    />
  </div>
</template>

<style scoped>
::v-deep .ProseMirror:focus {
  outline: none;
}

</style>
