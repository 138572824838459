<script lang="ts" setup>
import { defineProps, defineEmits, ref, inject } from 'vue'
import DialogModal from '@/Components/UI/Modals/DialogModal.vue'
import { MediaLibraryCollection } from 'media-library-pro-vue3-collection'
import { useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

const props = defineProps<{show: boolean}>()

const emit = defineEmits(['close', 'media-uploaded'])

const hasUploadsInProgress = ref<boolean>(false)
const isReadyToSubmit = ref<boolean>(false)

const form = useForm<{ files: string[] }>({
  files: []
})

const onClose = () => {
  emit('close')
}

const onSubmit = () => {
  form.put(route('media.store'), {
    preserveScroll: true,
    preserveState: true,
    onSuccess () {
      emit('close')
      emit('media-uploaded')
      form.reset()
    }
  })
}
</script>

<template>
  <DialogModal :show="props.show">
    <template #title>
      <h2 class="text-lg font-medium text-gray-800">
        Upload Media
      </h2>
    </template>
    <template #content>
      <div class="mt-2 text-sm text-gray-600 dark:text-gray-400">
        <media-library-collection
          v-model="form.files"
          name="files"
          :validation-errors="form.errors"
          :validation-rules="{ accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/avif', 'video/mp4', 'image/webp', 'video/mpeg', 'video/ogg', 'video/webm', 'video/x-msvideo' ] }"
          route-prefix="media"
          @change="form.files = $event"
          @is-ready-to-submit-change="isReadyToSubmit = $event"
          @has-uploads-in-progress-change="hasUploadsInProgress = $event"
        />
        <div
          v-if="form.errors.files"
          class="text-red-500 text-sm mt-1"
        >
          {{ form.errors.files }}
        </div>
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
        @click="onClose"
      >
        Close
      </button>
      <button
        type="button"
        :class="['ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500',
                 { 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-50': !isReadyToSubmit || hasUploadsInProgress || form.processing }]"
        :disabled="!isReadyToSubmit || hasUploadsInProgress || form.processing"
        @click.prevent="onSubmit"
      >
        Submit
      </button>
    </template>
  </DialogModal>
</template>
