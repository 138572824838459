<script lang="ts" setup>
import { usePage, Link, WhenVisible } from '@inertiajs/vue3'
import MediaCard from '@/Components/Media/MediaCard.vue'
import { MediaResource } from '@/Types/generated'
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { computed } from 'vue'

const page = usePage()

const media = computed<MediaResource[]|undefined>(() => page.props.media)
</script>

<template>
  <div
    id="gallery"
    class="w-full mt-8 scroll-mt-20"
  >
    <div class="flex items-center justify-center flex-col text-center">
      <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
        Image Gallery
      </h2>
      <div class="flex items-center flex-row justify-center mb-8">
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
        <div class="flex items-center">
          <div class="flex items-center">
            <LavenderIcon />
          </div>
        </div>
        <div class="text-gray-400 px-2">
          &#8212;&#8212;
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-0">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <WhenVisible
          data="media"
          :buffer="500"
        >
          <MediaCard
            v-for="(mediaItem, index) in media"
            :key="index"
            :media="mediaItem"
          />

          <template #fallback>
            <MediaCard
              v-for="index in 9"
              :key="index"
            />
          </template>
        </WhenVisible>
      </div>
    </div>

    <div class="flex justify-center mt-2">
      <Link
        prefetch
        class="mt-4 inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white tracking-widest hover:bg-gray-700 focus:bg-purple-700 active:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition ease-in-out duration-150"
        :href="route('media.index')"
      >
        Go to Gallery
      </Link>
    </div>
  </div>
</template>
