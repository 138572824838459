<script lang="ts" setup>
import Checkbox from '@/Components/UI/Inputs/CheckboxInput.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/UI/Inputs/InputError.vue'
import InputLabel from '@/Components/UI/Inputs/InputLabel.vue'
import PrimaryButton from '@/Components/UI/Buttons/PrimaryButton.vue'
import TextInput from '@/Components/UI/Inputs/TextInput.vue'
import { Head, Link, useForm } from '@inertiajs/vue3'
import { route as routeFn } from 'ziggy-js'
import { inject } from 'vue'

const route = inject<typeof routeFn>('route')

interface Form {
  email: string;
  password: string;
  remember: boolean;
}

const props = defineProps<{canResetPassword: boolean, status: string, background: string|null|undefined}>()

const form = useForm<Form>({
  email: '',
  password: '',
  remember: false
})

const submit = (): void => {
  form.post(route('login'), {
    onFinish: () => form.reset('password')
  })
}
</script>

<template>
  <GuestLayout :background="props.background">
    <Head title="Log in | Hargrave Wedding" />

    <div>
      <div
        v-if="status"
        class="mb-4 font-medium text-sm text-green-600"
      >
        {{ status }}
      </div>

      <form @submit.prevent="submit">
        <div>
          <InputLabel
            for="email"
            value="Email"
          />

          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="mt-1 block w-full"
            required
            autofocus
            autocomplete="username"
          />

          <InputError
            class="mt-2"
            :message="form.errors.email"
          />
        </div>

        <div class="mt-4">
          <InputLabel
            for="password"
            value="Password"
          />

          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="mt-1 block w-full"
            required
            autocomplete="current-password"
          />

          <InputError
            class="mt-2"
            :message="form.errors.password"
          />
        </div>

        <div class="block mt-4">
          <label class="flex items-center">
            <Checkbox
              v-model:checked="form.remember"
              name="remember"
            />
            <span class="ms-2 text-sm text-gray-600">Remember me</span>
          </label>
        </div>

        <div class="flex items-center justify-end mt-4">
          <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Forgot your password?
          </Link>

          <PrimaryButton
            class="ms-4"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            Log in
          </PrimaryButton>
        </div>
      </form>
    </div>
  </GuestLayout>
</template>
