<script lang="ts" setup>
import { computed, ref, inject } from 'vue'
import axios from 'axios'
import { Commentable, CommentResource } from '@/Types/generated'
import Comment from '@/Components/Comments/Comment.vue'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface AllCommentResponse {
  data: CommentResource[];
}

const props = defineProps<{
  page: { page: number; count: number };
  commentableType: Commentable;
  commentableId: string;
  isNested?: boolean;
}>()

const isNested = computed(() => props.isNested || false)

const hasLoaded = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const errorMessage = ref<string | null>(null)

const allComments = ref<AllCommentResponse>({
  data: []
})

const loadComments = async (isVisible: boolean) => {
  if (isLoading.value || !isVisible || hasLoaded.value) {
    return
  }

  isLoading.value = true

  try {
    const response = await axios.get(
      route('api.comments.index', {
        page: props.page.page,
        commentable_type: props.commentableType,
        commentable_id: props.commentableId
      })
    )
    allComments.value = response.data as AllCommentResponse
    hasLoaded.value = true
  } catch (error) {
    console.error('Error loading comments:', error)
    errorMessage.value = 'Failed to load comments. Please try again.'
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div :class="{ 'ml-8': isNested }">
    <template v-if="hasLoaded">
      <Comment
        v-for="comment in allComments.data"
        :key="comment.id"
        :comment="comment"
        :is-nested="isNested"
      />
    </template>

    <template v-else-if="isLoading">
      <div
        v-for="n in props.page.count"
        :key="n"
        class="mb-4 p-4 border border-gray-200 rounded-lg animate-pulse"
      >
        <div class="flex items-center mb-2">
          <div class="w-10 h-10 bg-gray-300 rounded-full mr-3" />
          <div class="h-4 bg-gray-300 rounded w-1/4" />
        </div>
        <div class="h-3 bg-gray-300 rounded w-full mb-2" />
        <div class="h-3 bg-gray-300 rounded w-3/4" />
      </div>
    </template>

    <template v-else-if="errorMessage">
      <div class="text-red-500 text-center mt-4">
        {{ errorMessage }}
        <button
          class="underline text-purple-600"
          @click="loadComments(true)"
        >
          Retry
        </button>
      </div>
    </template>

    <div
      v-observe-visibility="{
        callback: loadComments,
        throttle: 300,
      }"
    />
  </div>
</template>

<style scoped>
.ml-8 {
  margin-left: 2rem;
}

.animate-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
</style>
