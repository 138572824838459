<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, inject } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import { NotificationResource, UserResource } from '@/Types/generated'
import NotificationPage from '@/Components/Notifications/NotificationPage.vue'
import Notification from '@/Components/Notifications/Notification.vue'
import axios from 'axios'
import { route as routeFn } from 'ziggy-js'

const route = inject<typeof routeFn>('route')

interface Page {
  page: number;
  count: number;
}

const page = usePage()

const props = withDefaults(
  defineProps<{
    dark?: boolean,
  }>(),
  {
    dark: false
  }
)

const user = computed<UserResource|null>(() => page.props.user)

const isOpen = ref(false)
const newNotifications = ref<NotificationResource[]>([])
const allMarkedAsRead = ref<boolean>(false)

const totalUnreadCount = computed<number>(() => {
  const existingUnread = user.value.notifications?.unread_count ?? 0
  const newUnread = newNotifications.value.filter((notification) => notification.read_at === null).length

  return existingUnread + newUnread
})

const totalCount = computed<number>(() => {
  const existingCount = user.value.notifications?.count ?? 0
  const newCount = newNotifications.value.length

  return existingCount + newCount
})

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const notificationPages = computed<Page[]>(() => {
  const pages: Page[] = []
  const notificationCount = user.value.notifications?.count ?? 0
  const perPage = 25

  const totalPages = Math.ceil(notificationCount / perPage)
  const remainder = notificationCount % perPage ? notificationCount % perPage : perPage

  for (let i = 1; i <= totalPages; i++) {
    pages.push({
      page: i,
      count: i === totalPages ? remainder : perPage
    } as Page)
  }

  return pages
})

const markAllAsRead = () => {
  axios.post(route('api.notifications.mark-all-as-read'))
    .then(() => {
      newNotifications.value.forEach((notification) => {
        notification.read_at = new Date().toISOString()
      })
      if (user.value.notifications) {
        user.value.notifications.unread_count = 0
      }
      allMarkedAsRead.value = true
    })
    .catch(() => {
      // Handle error (optional)
    })
}

onMounted(() => {
  if (!user.value) {
    return
  }

  /* eslint-disable */
  window.Echo.private(`User.${user.value.id}`)
    .notification((notification) => {
      newNotifications.value.unshift(notification)
    })

  onUnmounted(() => {
    window.Echo.leave(`User.${user.value.id}`)
  })
  /* eslint-enable */
})

const goToNotification = (notification: NotificationResource) => {
  router.get(route('notifications.show', { notification: notification.id }))
  isOpen.value = false
}

</script>

<template>
  <div
    v-if="user"
    class="relative"
  >
    <button
      class="relative inline-flex items-center justify-center h-10 w-10 focus:outline-none"
      aria-label="Notifications"
      @click="toggleDropdown"
    >
      <svg
        :class="[
          'w-6',
          'h-6',
          {'text-gray-700': !props.dark},
          {'hover:text-gray-500': !props.dark},
          {'text-gray-300': props.dark},
          {'hover:text-gray-300': props.dark}
        ]"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6 6 0 00-5-5.917V4a2 2 0 10-4 0v1.083A6 6 0 004 11v3.159c0 .538-.214 1.055-.595 1.436L2 17h5m7 0a3.001 3.001 0 01-6 0m6 0H9"
        />
      </svg>

      <span
        v-if="totalUnreadCount"
        class="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/4 -translate-y-1/8"
      >
        {{ totalUnreadCount }}
      </span>
    </button>

    <div
      v-show="isOpen"
      class="absolute right-0 mt-2 w-96 bg-white border border-gray-200 rounded shadow-lg z-50"
    >
      <div
        v-if="totalCount"
        class="py-2 max-h-80 overflow-y-auto"
      >
        <button
          v-if="totalUnreadCount"
          class="block w-full text-left px-4 py-2 text-sm text-purple-700 hover:text-purple-900 font-bold"
          @click="markAllAsRead"
        >
          Mark all as read
        </button>

        <Notification
          v-for="notification in newNotifications"
          :key="notification.id ?? notification.created_at"
          :notification="notification"
          @click="goToNotification"
        />
        <NotificationPage
          v-for="notificationPage in notificationPages"
          :key="notificationPage.page"
          :page="notificationPage"
          :all-read="allMarkedAsRead"
          @click="goToNotification"
        />
      </div>
      <div
        v-else
        class="py-2 px-4 text-sm text-gray-700"
      >
        No notifications
      </div>
    </div>
  </div>
</template>
